import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "@iso/lib/helpers/rtl";

const ResendEmailConfirmStyleWrapper = styled.div`
  .ant-btn-primary.btn-success {
    background-color: #5e8c53;
    border-color: #5e8c53;
    border-radius:8px;
    &:hover {
      opacity: 0.8;
    }
  }

  .isoFormContentWrapper {
    width: 500px;
    height: 100%;
    overflow-y: auto;
    z-index: 10;
    position: relative;
  }

  .isoFormContent {
    min-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 15rem;
    position: relative;
    background-color: #ffffff !important;

    .signin-form-main {
      padding: 0px 50px 0px 50px !important;
    }
    .isoLogoWrapper {
      width: 100%;
      display: flex;
      padding-bottom: 50px;
      display:flex;
      justify-content :center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette("secondary", 2)};
      }
    }

    .isoFormHeadText {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      justify-content: center;

      h3 {
        font-family: Roboto, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        margin: 0px 0px 7px;
        color: ${palette("text", 0)};
      }

      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
        margin: 0px;
        color: rgb(151, 151, 151);
      }
    }

    .isoForgotPassForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      .isoHelperWrapper {
        margin-top: 35px;
        flex-direction: column;
      }

      .isoForgotPass {
        font-size: 12px;
        color: ${palette("text", 3)};
        margin-bottom: 10px;
        text-decoration: none;

        &:hover {
          color: ${palette("primary", 0)};
        }
      }

      .isoInputWrapper {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &:-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &::-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }
          &:-ms-input-placeholder {
            color: ${palette("grayscale", 0)};
          }
        }

        button {
          height: 42px;
          width: 100%;
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .isoFormContent {
      padding-top: 5rem !important;
    }
    .signin-heading-hi {
      justify-content: center;
    }
    .isoFormContent .signin-form-main {
      padding: 0px 150px 0px 150px ;
    }
  }
  @media only screen and (max-width: 768px) {
    .signInmain{
      width:100%;
        padding: 12px;
    height: 100vh;
    gap: 57px;
    flex-direction: column;
      }
    }
  @media only screen and (max-width: 425px) {
    .isoFormContent .signin-form-main{
    padding: 0px 0px 0px 0px !important;
    }
    .isoFormContent .isoLogoWrapper{
      margin-bottom :0px !important; 
      padding-bottom: 20px !important;
    }
    .signInmain{
      width:100% !important;
      display:block !important;
    }
  }
`;

export default WithDirection(ResendEmailConfirmStyleWrapper);
