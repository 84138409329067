import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const ContactAgentModallWrapper = styled(Modal)`
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover{
      opacity: 0.8;
    }
  }
  .ant-modal-body{
    padding: 0;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  a,.ant-upload .ant-btn:hover,.ant-upload .ant-btn:focus{
    border-color: #559f2f;
    color:#559f2f
  }
  &.mob-cannot-create .ant-btn-primary{
    display: none;
  }
  .btn-create-breed{
    background-color: #46AFCA;
    border-color: #46AFCA;
    &:hover{
      opacity: 0.8;
    }
    margin-top:30px;
    width: 100%;
    padding: 0;
  }
  .card_content{
    display:inline;
  }

  .card_value{
    float:right;
  }

  ${props => props.agent?.color_code ? 
    `.ant-input{
      border: 1px solid `+ props.agent.color_code +` !important;
    }
    .btn-success{
      background-color: `+ props.agent.color_code +`
    }
    .modal-title{
      color: `+ props.agent.color_code +`
    }
    .btn-success{
      background-color: `+ props.agent.color_code +`;
      border: 1px solid `+ props.agent.color_code +` !important;
    }
    .cancel-button{
      border: 1px solid `+ props.agent.color_code +` !important;
    }` : ''}
`;

export default WithDirection(ContactAgentModallWrapper);