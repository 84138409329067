import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const SARModalWrapper = styled(Modal)`
  &.user-access-modal {
    width: 640px !important;
  }
.cancel-button {
  background-color :#ABABAB;
  border-color :#ABABAB;
  color : #fff;
  height : 40px;
  width : 50%;
}
  

  thead{
    text-align : left;
  }
  .ant-collapse > .ant-collapse-item{
  border-bottom: none;
  }
  .manage-table .react-collapsible tbody .name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
  }
    .manage-property-header > .mp-title {
      width: 15%;
    }
    body #root .react-collapsible-theme .react-collapsible thead > tr > th {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #535763 ;
      text-align: left;
      background-color: #f9f9fb;
     
    }
    body #root .react-collapsible-theme .react-collapsible thead > tr > th span{
      display: inline;
    }
    .react-collapsible-theme .react-collapsible tbody > tr > td {
      color: #475467;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .manage-table{
      overflow: hidden;
    }
 
  @media(min-width:1440px){
    width: 740px !important;
  }
  &.user-access-modal {
    width: 740px !important;
  }

  .ant-modal-body {
    padding: 0;
  }
  .btn-success {
    background-color: #5e8c53;
    border-color: #5e8c53;
    margin-left: 10px;
    height : 40px; 
    width : 50%;
  }
  .btn-danger {
    background-color: #ea5252;
    border-color: #ea5252;
  }
  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3 {
      font-size: 1rem;
      color: #729d68;
    }
  }
  .form-body {
    padding: 12px 24px 12px 24px;
    .checkbox-contact {
      margin-bottom: 10px;
    }
    .footer-btn {
      display: flex;
    }
  }
  .footer-btn {
    display: flex;
    justify-content: flex-end;
  }

  .checkboxContacts .ant-checkbox-wrapper{
    display:flex;
    width: 48%;
  }
  .checkboxContacts{
    height: 150px;
    overflow: auto;
    display: flex;
    gap: 10px;
    flex-wrap:wrap;
  }
  .ant-pagination-item-active{
    border-color: #729d68;
  }
  .ant-modal-footer{
    border:0;
  }
  .ant-pagination-item-active a{
    color: #729d68;
  }
  .SarTable__Wrapper{
    padding: 16px;
  }
}  
`;

export default WithDirection(SARModalWrapper);
