import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const AlertDeviceWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  a{
    color: #729D68;
  }
  .ant-table-wrapper {
    min-width: 100%;
  }
  thead{
    text-align : left;
  }
  // tr:nth-child(odd) { background-color: #f1f3f6; }
  // tr:nth-child(even) {background-color: white;}
  
  .react-collapsible-theme .react-collapsible thead > tr > th {
    background-color:white;
  }
  .table-striped-rows thead {
    background-color: #f1f1f1;
  }
  th.ant-table-cell{
    color:#5E8D54;
  }
  .ant-pagination-item-active{
    border-color: #729D68;

  }
  .ant-pagination-item-active a {
    color: #729D68;
  }
  .ant-select-device{
    font-size:14px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item{
    border-radius: 5px;
  }
  .ant-select,.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state{
    color:white;
  }
  .ant-device-search,.ant-device-search:hover{
    border-color: #729D68;
    border-radius: 5px;
    padding: 8px;
  }
  .ant-device-search .ant-input-group{
    border: 1px solid #729D68;
  }
  .ant-device-search .ant-input {
    border: 0px !important;
  }
  .ant-btn-add-device{
    background-color:#729D68;
    color:white;
  }
  .ant-btn-add-device:hover{
    opacity:.9;
  }
  .ant-popconfirm .ant-btn-primary{
    background: #729D68;
  }
  #addDevice .ant-input{
    padding: 8px;
    width: 100%;
  }
  #addDevice .ant-form-item{
    width: 60%;
  }
  .reload-button-alert{
    color: #729D68;
    padding: 5px 8px;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .reload-button-alert .anticon-sync{
    line-height: 0;
  }
  .ant-btn-location {
    width: 40px;
height: 40px;

// left: 2px;
border-radius: 10px;

    padding: 5px 8px;
    color: black;
    font-size: 14px;
    background: #ECDD5D;
  }
  @media(max-width:820px){
    #addDevice .ant-form-item{
      width:50%;
    }
  }
  .manage-table{
    overflow : auto;
  }
  .manage-table {
    border-top: 1px solid #EAECF0 !important;
  }
`;
export default WithDirection(AlertDeviceWrapper);