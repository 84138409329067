import React, { useState, useEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import PageHeader from "@iso/components/utility/pageHeader";
import Box from "@iso/components/utility/box";
import AssetPage from "./Asset.styles";
import IntlMessages from "@iso/components/utility/intlMessages";
import { configs } from "@iso/components/Tables/configs";
import { Button, Dropdown, Input, Menu, Pagination, Popconfirm } from "antd";
import {
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PaperClipOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import AssetModal from "@iso/containers/Pages/PropertyMap/components/Asset/AssetModal";
import EditAircraftModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditAircraftModal";
import EditFeedModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditFeedModal";
import EditFuelModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditFuelModal";
import EditGeneralAssetModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditGeneralAssetModal";
import EditTreatmentModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditTreatmentModal";
import EditWaterModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditWaterModal";
import EditAnimalModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditAnimalModal";
import EditWeatherModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditWeatherModal";
import { assetService } from "@iso/services";
import { propertyService } from "../../../services/property.service";
import _ from "lodash";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import { assetStates } from "@iso/constants/assetStates";
import SelectPropertyModal from "@iso/containers/Pages/Property/SelectPropertyModal";
import { levelTypes } from "@iso/constants/levelTypes";
import L from "leaflet";
import * as turf from "@turf/turf";
import inside from "point-in-polygon";
import AssetViewSummary from "./AssetViewSummary";
import Loader from "../../../components/utility/loader";
import AddAssetHistoryModal from "../PropertyMap/components/Asset/AddAssetHistoryModal";
import { activityService } from "../../../services/activity.service";
const { openModal } = modalActions;

const Asset = (props) => {
  const [assets, setAssets] = useState([]);
  const dispatch = useDispatch();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activeProperty = localStorage.getItem("active_property");
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );

  const [liveStockData, setLiveStockData] = useState({});
  const [visibleEditLivestock, setVisibleEditLivestock] = useState(false);
  const [visibleViewSummary, setVisibleViewSummary] = useState(false);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [viewSummary, setViewSummary] = React.useState({});
  const [visibleAssetTreatment, setVisibilityAssetTreatment] =
    React.useState(false);
  const [visibleAssetFuel, setVisibilityAssetFuel] = React.useState(false);
  const [visibleAssetFeed, setVisibilityAssetFeed] = React.useState(false);
  const [visibleAssetWater, setVisibilityAssetWater] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [visibleAssetAircraft, setVisibilityAssetAircraft] =
    React.useState(false);
  const [visibleAssetOther, setVisibilityAssetOther] = React.useState(false);
  const [dataAsset, setDataAsset] = useState({});
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const [visibleAssetAnimal, setVisibilityAssetAnimal] = React.useState(false);
  const [visibleAssetWeather, setVisibilityAssetWeather] =
    React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchInput, setsearchInput] = React.useState("");
  const [viewHistory, setViewHistory] = React.useState(false);
  const [visibleAddHistory, setVisibleAddHistory] = useState(false);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedVisitor = searchQuery?.slice(startIndex, endIndex);
  const displayData =
    props.search_result &&
    props.search_result.Facility?.slice(startIndex, endIndex);

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        setPrimaryObjects(res.primary_objects);
      }
    });
  }, []);
  useEffect(() => {
    localStorage.setItem("assetLength", JSON.stringify(assets.length));
    if (props?.handleSafetyAssets) {
      // props.handlOpenmodal(showModal)
      props?.handleSafetyAssets(assets.length);
    }
  }, [assets]);
  useEffect(() => {
    if (activePropertyId) {
      updatePropertyDetail(activePropertyId);
    }
  }, [activePropertyId, updatePropertyDetail]);

  useEffect(() => {
    updateAssetData();
  }, [activePropertyId]);

  const updateAssetData = () => {
    if (activePropertyId) {
      setLoadingData(true)
      assetService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setAssets(res.facilities);
          setSearchQuery(res.facilities);
          setLoadingData(false);
        }
      });
    }
  };

  useEffect(() => {
    updateAssetData();
  }, [activePropertyId]);
  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      }
    });
  }, [activePropertyId]);

  const menu = (asset) => (
    <Menu onClick={handleMenuClick.bind(this, asset)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewSummary" icon={<EyeOutlined />}>
        <IntlMessages id="View Summary" />
      </Menu.Item>
      <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
        <IntlMessages id="Add History" />
      </Menu.Item>
      <Menu.Item key="history" icon={<FileTextOutlined />}>
        <IntlMessages id="View History" />
      </Menu.Item>
      {(activePermission == "owner" || activePermission == "admin") && (
        <p style={{ cursor: "pointer" }} className="deleteButton">
          <Popconfirm
            placement="bottomRight"
            title="You are about to delete individual record"
            onConfirm={() => {
              confirmDelete(asset);
            }}
            okText="Yes"
            cancelText="No"
            className="delete-button"
          >
            <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
              {<DeleteOutlined />}&nbsp;&nbsp;
              <IntlMessages id="propertyPage.managePage.delete" />
            </a>
          </Popconfirm>
        </p>
      )}
    </Menu>
  );
  const handleMenuClick = (asset, e) => {
    if (e.key === "editDetails") {
      setViewHistory(false);
      setDataAsset(asset);
      if (
        asset.type === "animal_treatment" ||
        asset.type === "fertiliser" ||
        asset.type === "vaccine"
      ) {
        setVisibilityAssetTreatment(true);
      }

      if (asset.type === "fuel" || asset.type === "chemical") {
        setVisibilityAssetFuel(true);
      }

      if (asset.type === "stock_feed") {
        setVisibilityAssetFeed(true);
      }

      if (asset.type === "water") {
        setVisibilityAssetWater(true);
      }

      if (asset.type === "aircraft") {
        setVisibilityAssetAircraft(true);
      }

      if (asset.type === "other") {
        setVisibilityAssetOther(true);
      }

      if (asset.category === "animal") {
        setVisibilityAssetAnimal(true);
      }

      if (asset.category === "weather") {
        setVisibilityAssetWeather(true);
      }

      if (
        asset.category !== "inventory" &&
        asset.category !== "animal" &&
        asset.category !== "weather"
      ) {
        setVisibilityAssetOther(true);
      }
    }
    if (e.key === "viewSummary") {
      setVisibleViewSummary(true);
      setViewSummary(asset);
    }
    if (e.key === "history") {
      setDataAsset(asset);

      if (
        asset.type === "animal_treatment" ||
        asset.type === "fertiliser" ||
        asset.type === "vaccine"
      ) {
        setVisibilityAssetTreatment(true);
        setViewHistory(true);
      }

      if (asset.type === "fuel" || asset.type === "chemical") {
        setVisibilityAssetFuel(true);
        setViewHistory(true);
      }

      if (asset.type === "stock_feed") {
        setVisibilityAssetFeed(true);
        setViewHistory(true);
      }

      if (asset.type === "water") {
        setVisibilityAssetWater(true);
        setViewHistory(true);
      }

      if (asset.type === "aircraft") {
        setVisibilityAssetAircraft(true);
        setViewHistory(true);
      }

      if (asset.type === "other") {
        setVisibilityAssetOther(true);
        setViewHistory(true);
      }

      if (asset.category === "animal") {
        setVisibilityAssetAnimal(true);
        setViewHistory(true);
      }

      if (asset.category === "weather") {
        setVisibilityAssetWeather(true);
        setViewHistory(true);
      }

      if (
        asset.category !== "inventory" &&
        asset.category !== "animal" &&
        asset.category !== "weather"
      ) {
        setVisibilityAssetOther(true);
        setViewHistory(true);
      }
    }
    if (e.key === "addHistory") {
      setDataAsset(asset);
      setVisibleAddHistory(true);
    }
  };

  const confirmDelete = (del_asset) => {
    if (del_asset.id) {
      assetService.destroy(del_asset.id).then((res) => {
        if (res.code === "0000") {
          updateAssetData();
        }
      });
    }
  };

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);
  };

  const columns = [
    {
      label: <IntlMessages id="assetPage.table.name" />,
      accessor: "title",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let asset = cell.row;

        const hasAttachment = asset.attachment; // Replace with your attachment presence condition
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: hasAttachment ? 10 : 0 }}>
              {asset.title}
            </div>
            {hasAttachment.length > 0 && (
              <PaperClipOutlined style={{ color: "#5e8c53" }} />
            )}{" "}
            {/* Add the PaperclipIcon conditionally */}
          </div>
        );
      },
    },
    {
      label: <IntlMessages id="assetPage.table.category" />,
      accessor: "category_label",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="assetPage.table.assetType" />,
      accessor: "type_label",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="assetPage.table.stateOrLevel" />,
      accessor: "state_label",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let asset = cell.row;
        if (asset.category != "water") {
          return assetStates.map((value, index) => {
            const opacity = 0.3;
            const rgbValues = value.color.match(/\d+/g);
            const colorWithOpacity = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;
            if (value.value == asset.state) {
              return (
                <div
                  className=""
                  style={{
                    display: "flex",
                    backgroundColor: colorWithOpacity,
                    padding: "5px 10px",
                    borderRadius: "10px",
                    gap: "6px",
                    maxWidth: "130px",
                    alignItems: "center",
                  }}
                >
                  <ColorItemPreview
                    style={{ backgroundColor: value.color }}
                  ></ColorItemPreview>
                  &nbsp;&nbsp;
                  <div>{value.label}</div>
                </div>
              );
            }
          });
        } else {
          return <div>{asset.state}</div>;
        }
      },
    },
    {
      label: <IntlMessages id="assetPage.table.location" />,
      accessor: "primary_object",
      minWidth: 200,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: <IntlMessages id="assetPage.table.lastUsed" />,
      accessor: "last_activity_date",
      minWidth: 200,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: (cell) => {
        let asset = cell.row;
        return asset.last_activity_date
          ? moment(asset.last_activity_date).format("DD/MM/YYYY")
          : "N/A";
      },
    },
    {
      label: <IntlMessages id="assetPage.table.remaining" />,
      accessor: "remaining_label",
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
    },
    {
      label: "",
      accessor: "actions",
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8,
      noTitle: true,
      CustomComponent: (cell) => {
        let asset = cell.row;
        return (
          <Dropdown overlay={menu(asset)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const onSaved = (savedAsset) => {
    let tmpAssets = _.clone(assets);
    const Index = _.findIndex(tmpAssets, (asset) => {
      return asset.id === savedAsset.id;
    });

    if (Index === -1) {
      tmpAssets.push(savedAsset);
    } else {
      tmpAssets[Index] = savedAsset;
    }
    setAssets(tmpAssets);
    updatePropertyDetail(activePropertyId);
    setSearchQuery(tmpAssets);
  };

  const showModal = () => {
    const modalData = {
      type: "asset",
      canCreate: true,
    };
    dispatch(openModal(modalData));
  };

  const cancelEditTreatmentModal = () => {
    setVisibilityAssetTreatment(false);
  };

  const cancelEditFuelModal = () => {
    setVisibilityAssetFuel(false);
  };

  const cancelEditFeedModal = () => {
    setVisibilityAssetFeed(false);
  };

  const cancelEditWaterModal = () => {
    setVisibilityAssetWater(false);
  };

  const cancelEditAircraftModal = () => {
    setVisibilityAssetAircraft(false);
  };

  const cancelEditOtherModal = () => {
    setVisibilityAssetOther(false);
  };

  const cancelEditAnimalModal = () => {
    setVisibilityAssetAnimal(false);
  };

  const cancelEditWeatherModal = () => {
    setVisibilityAssetWeather(false);
  };

  const listAllMarkerArea = (object) => {
    const polygon = L.polygon([object.area]);
    const bounds = polygon.getBounds();

    var y_min = polygon.getBounds().getWest();
    var y_max = polygon.getBounds().getEast();
    var x_min = polygon.getBounds().getSouth();
    var x_max = polygon.getBounds().getNorth();
    const cellSide = 0.05;
    const options = { units: "kilometers" };
    const extent = [y_min, x_min, y_max, x_max];
    const grid = turf.pointGrid(extent, cellSide, options);

    let positionMarker = [];
    var area = polygon.getLatLngs()[0].map(function (point) {
      return [point.lat, point.lng];
    });
    _.forEach(grid.features, function (point) {
      if (
        inside(
          [point?.geometry?.coordinates[1], point?.geometry?.coordinates[0]],
          area
        ) &&
        point?.geometry?.coordinates[1] < object.lat_centroid
      ) {
        positionMarker.push({
          lat: point?.geometry?.coordinates[1].toFixed(8),
          lng: point?.geometry?.coordinates[0].toFixed(8),
        });
      }
    });

    // horizontally sort : lat
    positionMarker.sort(function (a, b) {
      if (a.lat < b.lat) return -1;
      if (a.lat > b.lat) return 1;
      if (a.lat == b.lat) {
        if (a.lat > b.lat) return -1;
        else return 1;
      }
    });

    // reverse array
    if (x_min > 0 && x_max > 0) {
      positionMarker = [...positionMarker].reverse();
    }

    // arrange middle in array
    let xHorizontal = positionMarker.filter(
      (x) => x.lat == positionMarker[0].lat
    );

    let xMiddle = xHorizontal[Math.round((xHorizontal.length - 1) / 2)];
    let yAllMiddle = positionMarker.filter((y) => y.lng == xMiddle.lng);
    let YMiddle = yAllMiddle[Math.round((yAllMiddle.length - 1) / 2)];
    let xUnderName = positionMarker.filter((x) => x.lat == YMiddle.lat);

    let xMarkerSort = [];
    _.forEach(xUnderName, function (pM, i) {
      // get middle item
      xMiddle = xUnderName[Math.round((xUnderName.length - 1) / 2)];

      // get index middle item
      var indexMiddle = _.findIndex(xUnderName, xMiddle);
      if (!_.find(xMarkerSort, xMiddle)) {
        xMarkerSort.push(xMiddle);
        xUnderName.splice(indexMiddle, 1);
      }
    });

    return xMarkerSort;
  };

  const handleCancelSearch = () => {
    setsearchInput("");
    setSearchQuery(assets);
  };
  const handleSearch = (e) => {
    setsearchInput(e.target.value);
    const searchTerm = searchInput.toLowerCase();
    // if (e.key === "Enter") {
    const filteredAssets = assets.filter((asset) => {
      return (
        asset.title.toLowerCase().includes(searchTerm) ||
        asset.category_label.toLowerCase().includes(searchTerm) ||
        asset.type_label.toLowerCase().includes(searchTerm) ||
        asset.state_label.toLowerCase().includes(searchTerm) ||
        (asset.primary_object &&
          asset.primary_object.toString().toLowerCase().includes(searchTerm)) ||
        (asset.last_activity_date &&
          moment(asset.last_activity_date)
            .format("DD/MM/YYYY")
            .includes(searchTerm)) ||
        asset.remaining_label.toLowerCase().includes(searchTerm)
      );
    });
    setSearchQuery(filteredAssets);
    setCurrentPage(1); // Reset the current page to 1 when the search term changes
    // }
  };

  const cancelModalHistory = () => {
    setVisibleAddHistory(false);
  };
  // const updateDataAssetHistory = React.useCallback(
  //   (asset_id, modal_type) => {
  //     activityService.getList(asset_id, modal_type).then((res) => {
  //       if (res.code === "0000") {
  //         setAssetHistory(res.activities.reverse());
  //         //setLoading(false);
  //       }
  //     });
  //   },
  //   [assetHistory]
  // );
  return (
    <LayoutWrapper>
      <AssetPage>
        <Box id="containerBox">
          <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Asset</h2>
            </div>
            {props.search_result ? (
              ""
            ) : (
              <>
                <Input
                  placeholder="Search…"
                  size="large"
                  className="search-bar"
                  value={searchInput}
                  onChange={(e) => handleSearch(e)}
                  suffix={
                    !searchInput && (
                      <SearchOutlined className="site-form-item-icon" />
                    )
                  }
                  prefix={
                    !searchInput ? (
                      <>
                        <SearchOutlined className="site-form-item-icon" />
                      </>
                    ) : (
                      <CloseCircleOutlined
                        onClick={handleCancelSearch}
                        className="site-form-item-cancel"
                      />
                    )
                  }
                />
                <div className="button-group">
                  {(activePermission == "owner" ||
                    activePermission == "admin") && (
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      className="btn-success"
                      onClick={showModal}
                    >
                      <IntlMessages id="assetPage.managePage.addAsset" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
          {props.search_result &&
          props.search_result.Facility &&
          props.search_result.Facility.length > 0 ? (
            <>
              <div className="manage-table">
                <Table
                  columns={columns}
                  rows={displayData}
                  containerId="containerBox"
                  showPagination={false}
                  rowSize={99999}
                />
              </div>
              {props.search_result?.Facility?.length > 10 && (
                <div style={{ padding: "16px" }}>
                  <Pagination
                    current={currentPage}
                    total={props.search_result.Facility?.length}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100"]}
                    onShowSizeChange={handlePageSizeChange}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="manage-table">
              {loadingData ? (
                <Loader />
              ) : (
                <>
                  {!props.search_result &&
                  displayedVisitor &&
                  displayedVisitor.length > 0 ? (
                    <>
                      <Table
                        columns={columns}
                        rows={displayedVisitor}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                        showSearch={false}
                      />
                      <div style={{ padding: "16px" }}>
                        <Pagination
                          current={currentPage}
                          total={searchQuery?.length}
                          pageSize={pageSize}
                          onChange={handlePageChange}
                          showSizeChanger
                          pageSizeOptions={["10", "20", "50", "100"]}
                          onShowSizeChange={handlePageSizeChange}
                        />
                      </div>
                    </>
                  ) : (
                    <h1 style={{ padding: "15px 25px", color: "gray" }}>
                      <IntlMessages id="No assets found under this property" />
                    </h1>
                  )}{" "}
                </>
              )}
            </div>
          )}
        </Box>
        <AssetModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          listAllMarkerArea={listAllMarkerArea}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
        <EditTreatmentModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetTreatment={visibleAssetTreatment}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditTreatmentModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
        <EditFuelModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetFuel={visibleAssetFuel}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditFuelModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
        <EditFeedModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetFeed={visibleAssetFeed}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditFeedModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
        <EditWaterModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetWater={visibleAssetWater}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditWaterModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
        <EditAircraftModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetAircraft={visibleAssetAircraft}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditAircraftModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
        <EditGeneralAssetModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          updatePropertyDetail = {updatePropertyDetail}
          visibleAssetOther={visibleAssetOther}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditOtherModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
          updatePropertyDetail = {updatePropertyDetail}
          setVisibleAddHistory={setVisibleAddHistory}
          visibleAddHistory={visibleAddHistory}
        />
        <EditAnimalModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetAnimal={visibleAssetAnimal}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditAnimalModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
        <EditWeatherModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetWeather={visibleAssetWeather}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditWeatherModal}
          setViewHistory={setViewHistory}
          viewHistory={viewHistory}
        />
      </AssetPage>

      <SelectPropertyModal
        visibleSelectProperty={visibleSelectProperty}
        cancelSelectPropertyModal={cancelSelectPropertyModal}
      />
      <AssetViewSummary
        visibleViewSummary={visibleViewSummary}
        setVisibleViewSummary={setVisibleViewSummary}
        viewSummary={viewSummary}
      />
      <AddAssetHistoryModal
        history="Add"
        visibleHistory={visibleAddHistory}
        cancelModal={cancelModalHistory}
        primaryObjects={primaryObjects}
        model="facilities"
        dataId={dataAsset?.id}
        propertyId={props.propertyId}
      />
    </LayoutWrapper>
  );
};

export default Asset;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div {
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
