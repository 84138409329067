import React, { useState, useEffect,useRef,useCallback } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber, Checkbox, TimePicker,Radio,Upload,Space,Tag } from 'antd';
import { activityPolicy } from '@iso/constants/activityPolicy';
import { CloseOutlined, UploadOutlined,EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import moment from 'moment';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import { assetService, propertyService,healthsafety } from '@iso/services';
import ActivityWrap from './ActivityWrap.styles';
import EditActivityModal from './EditActivityModal';
import styled from "styled-components";
import Modal from '@iso/components/Feedback/Modal';

const { Option } = Select;

const ListAcivity = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewActivity, setViewActivity] = useState([]);
  const [visibleEditActivity, setVisibleEditActivity] = useState(false);
  const [visibleDeleteActivity, setVisibleDeleteActivity] = useState(false);
  // const [dataActivity, setDataActivity] = useState([]);
  const [activityId, setActivityId] = useState('');
  const mapRef = useRef();

  // useEffect(() => {
  //   if(props.dataId){
  //     setDataActivity(props.dataActivity)
  //   }
  // }, [props.dataId]);

  // const getDataActivity = () => {
  //   healthsafety.getDataActivity(props.dataId).then(res => {
  //     if(res.code === '0000'){
  //       setDataActivity(res.activity_policy);
  //     }
  //   });
  // }
  const onCancelEditActivity  = () => {
    setVisibleEditActivity(false);
  }
  const onHandleEditActivity = (activity) => {
    setViewActivity(activity);
    setVisibleEditActivity(true);
  } 
  const onHandleDeleteActivity = (activity) => {
    setVisibleDeleteActivity(true);
    setActivityId(activity.id);
  }

  const handleCancelActivity = () =>{
    setVisibleDeleteActivity(false);
  } 

  const handleDeleteActivity = () =>{
    healthsafety.destroyActivity(activityId).then(res => {
      if(res.code === '0000'){
        props.getDataActivity(props.dataId);
        setVisibleDeleteActivity(false);
      }
    });
  }

  return(
    <ActivityWrap>
      {
        props.dataActivity.map((activity, index) => { 
                    
          return(
              <div className="wrap-card-activity">
                <div className="wrap-btn-activity"> 
                  <Button type="link" className="btn-n-br btn-edit-activity" onClick={onHandleEditActivity.bind(this, activity)}>
                    <EditOutlined />
                  </Button>
                  <Button type="link" className="btn-n-br btn-delete-activity" onClick={onHandleDeleteActivity.bind(this, activity)}>
                    <CloseOutlined/>
                  </Button> 
                </div>
                <Card title={activity.type.replace(/_/g, ' ')} bordered={true} className="card-activity-title">
                  { activity.type === 'meeting' &&
                    <div>
                      <p><b>Meeting Name: </b> {activity.meeting_name}</p>
                      <p><b> Meeting Attendees: </b> 
                     { activity.metadata.attendees ?
                      activity.metadata.attendees.map((value,key) => {
                        return (
                          <Tag color="#559f2f">{value}</Tag>
                        )
                      })
                      : ''
                     }
                      </p>
                      <p><b> Meeting Actions: </b> {activity.metadata.actions}</p>
                      <p><b> Meeting Notes: </b> {activity.metadata.notes}</p>
                      <p><b> Date Time: </b> {activity.date_time}</p>
                    </div>
                  }    
                  { activity.type === 'comment' &&
                    <div>
                      <p><b> Comments: </b> {activity.comments}</p>
                      {activity.metadata.point && 
                      <p><b> Location: </b> <a href={"https://maps.google.com?q="+activity.metadata.point.lat+","+activity.metadata.point.lng } target="_blank" className="btn-edit-activity"> Open Map</a></p>
                      }
                      <p><b> Date Time: </b> {activity.date_time}</p>
                      <p> <b> Attachment: </b>
                      {
                        activity.attachment.map((value, key) => {
                          let nameUrl = value.url.replace("/uploads/attachments/", "");
                          return(
                            <div>
                            <a className="attachment-activity" href={process.env.REACT_APP_API_BASE_URL+value.url} download={process.env.REACT_APP_API_BASE_URL+value.url} target="_blank">{nameUrl}</a>
                            <br/>
                            </div>
                          )
                        })
                      }
                      </p>
                    </div>
                  }    
                  {activity.type === 'update' &&
                    <div>
                      <p> <b>Comments: </b> {activity.metadata.comments}</p>
                      <p><b> Date Time: </b> {activity.date_time}</p>
                      <p> <b> Attachment: </b>  
                      {
                        activity.attachment.map((value, key) => {
                          let nameUrl = value.url.replace("/uploads/attachments/", "");
                          return(
                            <div>
                              <a className="attachment-activity" href={process.env.REACT_APP_API_BASE_URL+value.url} download={process.env.REACT_APP_API_BASE_URL+value.url} target="_blank">{nameUrl}</a><br/>
                            </div>
                          )
                        })
                      }
                      </p>
                    </div>
                  }
                  {activity.type === 'safety_walk' &&
                    <div>
                      <p> <b>Notes: </b> {activity.metadata.notes}</p>
                      <p><b> Date Time: </b> {activity.date_time}</p>
                       <p><b> Attendees: </b> 
                       { activity.metadata.attendees ?
                        activity.metadata.attendees.map((value,key) => {
                          return (
                            <Tag color="#559f2f">{value}</Tag>
                          )
                        })
                        : ''
                       }
                      </p>
                      <p> <b> Attachment: </b>  
                      {
                        activity.attachment.map((value, key) => {
                          let nameUrl = value.url.replace("/uploads/attachments/", "");
                          return(
                            <div>
                              <a className="attachment-activity" href={process.env.REACT_APP_API_BASE_URL+value.url} download={process.env.REACT_APP_API_BASE_URL+value.url} target="_blank">{nameUrl}</a><br/>
                            </div>
                          )
                        })
                      }
                      </p>
                    </div>
                  }
                  {activity.type === 'induction' &&
                    <div>
                      <p> <b>Comments: </b> {activity.metadata.comments}</p>
                      <p><b> Date Time: </b> {activity.date_time}</p>
                      <p><b> Person Running Induction: </b> {activity.metadata.person_running_induction}</p>
                       <p><b> Attendees: </b> 
                       { activity.metadata.attendees ?
                        activity.metadata.attendees.map((value,key) => {
                          return (
                            <Tag color="#559f2f">{value}</Tag>
                          )
                        })
                        : ''
                       }
                      </p>
                     
                    </div>
                  }
                  {activity.type === 'toolbox_meeting' &&
                    <div>
                      <p> <b>Comments: </b> {activity.metadata.comments}</p>
                      <p><b> Date Time: </b> {activity.date_time}</p>
                      <p><b> Actions: </b> {activity.metadata.actions}</p>
                      <p><b> Attendees: </b> 
                       { activity.metadata.attendees ?
                        activity.metadata.attendees.map((value,key) => {
                          return (
                            <Tag color="#559f2f">{value}</Tag>
                          )
                        })
                        : ''
                       }
                      </p>
                      <p> <b> Attachment: </b>  
                      {
                        activity.attachment.map((value, key) => {
                          let nameUrl = value.url.replace("/uploads/attachments/", "");
                          return(
                            <div>
                              <a className="attachment-activity" href={process.env.REACT_APP_API_BASE_URL+value.url} download={process.env.REACT_APP_API_BASE_URL+value.url} target="_blank">{nameUrl}</a><br/>
                            </div>
                          )
                        })
                      }
                      </p>
                     
                    </div>
                  }  
                  {activity.type === 'hazard' &&
                    <div>
                      <p> <b>Comments: </b> {activity.metadata.comments}</p>
                      <p><b> Date Time: </b> {activity.date_time}</p>
                      <p><b> Actions: </b> {activity.metadata.actions}</p>
                      <p><b> Hazard type: </b> {activity.metadata.hazard_type}</p>
                      {activity.metadata.hazard_type === 'other' &&
                       <p><b> Hazard type other: </b> {activity.metadata.hazard_type_other}</p>
                      }
                      {activity.metadata.point && 
                        <p><b> Location: </b> <a href={"https://maps.google.com?q="+activity.metadata.point.lat+","+activity.metadata.point.lng } target="_blank" className="btn-edit-activity"> Open Map</a></p>
                      }
                     
                    </div>
                  }
                </Card>
              </div>
            )
         
  
       })
      }
      <EditActivityModal 
        dataId= {props.dataId}
        getDataActivity={props.getDataActivity}
        viewActivity={viewActivity}
        visibleEditActivity={visibleEditActivity} 
        onCancelEditActivity={onCancelEditActivity} 
      />
      <DeleteActivityModal 
        className="modal-delete-activity" 
        title="Delete Activity" 
        visible={visibleDeleteActivity} 
        onOk={handleDeleteActivity} 
        onCancel={handleCancelActivity}>
        <p>Do you want to delete?</p>
      </DeleteActivityModal>

    </ActivityWrap>
  )

}

export default ListAcivity;

const DeleteActivityModal = styled(Modal)`
  .ant-btn{
    color: #559f2f;
    border-color:#559f2f;
  }  
  .ant-btn.ant-btn-primary,.ant-btn.ant-btn-primary:hover{
    background:#65bb38;
    color: #fff;
  }   
  
  .ant-btn:hover{
    color: #559f2f;
    border-color:#559f2f;
  }
`;