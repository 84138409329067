import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const AgentModalWrapper = styled(Modal)`
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #5e8c53;
    border-color: #5e8c53;    
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state{
    color: #fff !important;
  }

  .ant-switch{
    margin-top: 20px;
  }

  .ant-modal-body{
    padding: 12px 24px 12px 24px;

  }
  .color-color {
    width: 36px;
    height: 14px;
    borderRadius: 2px;
  }
  .color-swatch {
    padding: 3px;
    background: #fff;
    borderRadius: 1px;
    boxShadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: 'pointer';
    border: 1px solid;
    cursor: pointer
  }
  .color-popover {
    position: absolute;
    z-index: 20;
  }
  .color-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  
`;

export default WithDirection(AgentModalWrapper);