import React,{useCallback,useEffect,useState} from 'react';
import { Layout } from 'antd';
import { options } from './options';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@iso/components/utility/logo';
import SidebarWrapper from './Sidebar.styles';
import SubscriptionModal from "../../containers/Pages/Property/SubscriptionModal";
import SidebarMenu from './SidebarMenu';
import siteConfig from '@iso/config/site.config';
import { propertyService,subscriptionService } from '@iso/services';
import { useSelector, useDispatch } from 'react-redux';
import closeIcon from '../../assets/images/closeIcon.png'
import collepsIcon from '../../assets/images/collepsIcon.svg';

const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

export default function Sidebar() {
  const dispatch = useDispatch();
  
  const { toggleCollapsed, toggleChangeText } = appActions;
  const [stripePlan, setStripePlan] = useState(null);
  const [collapseIcon, setCollapseIcon] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const [openSubscription, setOpenSubscription] = useState(false);
  const {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);
  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );
  const properties = useSelector((state) => state.property.properties);
  const activeId = useSelector((state) => state.property.activePropertyId);
  const currentUser = useSelector((state) => state.user.user);
  // const activePermission = useSelector((state) => state.permission.activePermission);

  const activePropertyId = useSelector((state) => state.property.activePropertyId);

  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));

  const handleToggle = React.useCallback(() => {
    dispatch(toggleCollapsed());
    dispatch(toggleChangeText());
    setCollapseIcon(true);
    setIsSectionVisible(!isSectionVisible);
  }, [dispatch,isSectionVisible]);
  
  function handleClick(e) {
    dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }
  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };
  const handleMailto = () => {
    const mailtoLink = 'mailto:support@airagri.com.au';
    window.location.href = mailtoLink;
  }
  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = event => {
    if (collapsed && openDrawer === false) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };
  const submenuStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: customizedTheme.textColor,
  };
  const submenuColor = {
    color: customizedTheme.textColor,
  };
  const propertyOwnerRoutes = ['manage-visitor', 'manage-inspection'];
  const handleOpenSubscriptionModal = () => {
    setOpenSubscription(true);
  };
  const cancelUpgradeModal = () => {
    setOpenSubscription(false);
  };
  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={isCollapsed}
        width={240}
        className="isomorphicSidebar"
        // onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={styling}
      >
        <Logo collapsed={isCollapsed} />
        <Scrollbars style={{ height: height - 70 }}>
          <Menu
            onClick={handleClick}
            theme="dark"
            className="isoDashboardMenu"
            mode={mode}
            openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={current}
            onOpenChange={onOpenChange}
          >
            {options(properties, activeId, currentUser.is_system_admin).map(singleOption => (
             (!propertyOwnerRoutes.includes(singleOption.key) || activePermission === 'owner') &&
                <SidebarMenu
                  key={singleOption.key}
                  submenuStyle={submenuStyle}
                  submenuColor={submenuColor}
                  singleOption={singleOption}
                />
            ))}
          </Menu>
          {!activeProperty || activeProperty?.subscription_label === process.env.REACT_APP_STRIPE_PLAN_GOLD_ANNUAL || activeProperty?.subscription_label === process.env.REACT_APP_STRIPE_PLAN_GOLD ? '' :
           !collapsed && (
            <div className='upgradeCust'>
              <div className="mailmainBox"><img src={process.env.PUBLIC_URL + "./images/icons/Icon.svg"} className="arrowImg" alt="sdfsdf"></img></div>
              <div className="subDivBox">
                <div className="infoBox">
                  Upgrade to <span className="pro">{activeProperty?.subscription_name == "Bronze - Free" ?"Gold" :(activeProperty?.subscription_name == "Silver" ?"Gold" :'')}</span> for premium
                  features
                </div>
                <button
                  className="infoBtn"
                  onClick={handleOpenSubscriptionModal}
                >
                  Upgrade
                </button>
              </div>
            </div>
          )
          }
          {
          !isCollapsed && 
            <div className="footer-text" style={{color: '#B0B0B0', padding: '1.5rem'}}>{siteConfig.footerText}</div>
          }
        </Scrollbars> 
        <div
          className={
            collapseIcon
              ? "triggerBtn menuCollapsed"
              : "triggerBtn menuOpen"
          }
          style={{ cursor: "pointer" }}
          onClick={handleToggle}
        >
          <img
            className="des-icon-menu"
            src={closeIcon}
            // src={process.env.PUBLIC_URL + "./images/icons/collepsIcon.svg"} 
            style={{ marginRight: "20px" }}
            alt="collepse-icon"
          />
        </div>
      </Sider>
      {openSubscription ?
      <SubscriptionModal
        openSubscription={openSubscription}
        setOpenSubscription = {setOpenSubscription}
        property={activeProperty}
        cancelModal={cancelUpgradeModal}
      />:''}
    </SidebarWrapper>
  );
}