import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const CheckinUserProfileWrapper = styled(Modal)`
  &.user-access-modal{
    width: 1200px!important;
  }
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: none!important;
    
  }
  h1{
    font-size: 1rem;
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }

  .signature-area canvas{
    border: solid 1px black;
    max-width: 100%;
  }

  .signature-image {
    width: 150px;
  }

  .signature-clear-button {
    display: block;
  }

  .form-qr-area {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    .ant-typography {
      margin-top: 20px;
      padding: 5px;
      border: 1px solid #719E67;
      border-radius: 5px;
      svg {
        fill: #719E67;
      }
    }
  }

  .btn-view-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .visitor-information{
    padding: 15px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    margin-bottom: 15px;
    .visitor-title{
      margin-bottom: 10px
    }
  }
`;

export default WithDirection(CheckinUserProfileWrapper);