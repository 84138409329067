import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const WeatherWrapper = styled.div`
  position: relative;
  h2 {
    font-size: 0.8em;
  }
  .temperature_weather{
    .sc-bfhvDw{
      @media only screen and (max-width: 767px) {
				overflow-x:auto;
        height:auto;
			}
      span{
				@media only screen and (max-width: 767px) {
					width:auto !important;
					height:auto !important;
				}
			}
    }
  }
  h1 {
    color: #729D68;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .loading-weather-chart{
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #719e67;
    z-index: 1;
  }
  .ant-col {
    padding: 0.25rem;
  }
  #weather-chat-div .ant-select{
    margin-right: 5px;
  }
  .statistic-card {
    background: #FFFFFF;
    /* Primary/Light Grey */
    padding : 10px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
  
  }
  // svg#raphael-paper-2{
  //   height : 80% !important;
  //   width : 100% !important;
  // }

`;

export default WithDirection(WeatherWrapper);