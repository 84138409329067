import Search from "@iso/containers/Pages/Search/Search";
import Dashboard from '@iso/containers/Pages/Dashboard/Dashboard';
import Property from '@iso/containers/Pages/Property/Property';
import HealthSafety from '@iso/containers/Pages/HealthSafety/Dashboard';
import PropertyMap from '@iso/containers/Pages/PropertyMap/PropertyMap';
import Mob from '@iso/containers/Pages/Mob/Mob';
import Task from '@iso/containers/Pages/Task/Task';
import LiveStock from '@iso/containers/Pages/LiveStock/LiveStock';
import PropertyArea from '@iso/containers/Pages/PropertyArea/PropertyArea';
import SafeFarm from '@iso/containers/Pages/SafeFarm/SafeFarm';
import Profile from '@iso/containers/Pages/Profile/Profile';
import Asset from '@iso/containers/Pages/Asset/Asset';
import Weather from '@iso/containers/Pages/Weather/Weather';
import { Form } from '@iso/containers/Pages/Form/Form';
import Marketplace from '@iso/containers/Pages/Marketplace/Marketplace';
import ProductDetail from '@iso/containers/Pages/Marketplace/ProductDetail';
import ProductList from '@iso/containers/Pages/Marketplace/ProductList';
import ManageUserProduct from '@iso/containers/Pages/ManageUserProduct/ManageUserProduct';
import AdminDashboard from '@iso/containers/Pages/AdminPages/Dashboard/Dashboard';
import ManageProductCategory from '@iso/containers/Pages/AdminPages/ManageProductCategory/ManageProductCategory';
import Directory from '@iso/containers/Pages/Directory/Directory';
import ShareMap from '@iso/containers/Pages/ShareMap/ShareMap';
import FormSubmission from '@iso/containers/Pages/Form/FormSubmission';
import Inspection from '@iso/containers/Pages/Form/Inspection';
import Visitor from '@iso/containers/Pages/Visitor/Visitor';
import StopDeviceAlarmRequest from "@iso/containers/Pages/StopDeviceAlarmRequest/StopDeviceAlarmRequest";
import VerifyElapsedPage from "@iso/containers/Pages/VerifyElapsedPage/VerifyElapsedPage";

export default {
  Dashboard: {
    component: Dashboard,
    path: '/dashboard'
  },
  Property: {
    component: Property,
    path: '/manage-properties'
  },
  PropertyMap: {
    component: PropertyMap,
    path: '/map'
  },
  Mob: {
    component: Mob,
    path: '/manage-mobs'
  },
  LiveStock: {
    component: LiveStock,
    path: '/manage-livestocks'
  },
  Task: {
    component: Task,
    path: '/manage-tasks'
  },
  PropertyArea: {
    component: PropertyArea,
    path: '/manage-areas'
  },
  Profile: {
    component: Profile,
    path: '/profile'
  },
  Asset: {
    component: Asset,
    path: '/manage-assets'
  },
  Weather: {
    component: Weather,
    path: '/weather'
  }, 
  HealthSafety: {
    component: HealthSafety,
    path: '/health-safety'
  },  
  SafeFarm: {
    component: SafeFarm,
    path: '/safe-farm'
  },
  Form: {
    component: Form,
    path: '/manage-forms'
  },
  Marketplace: {
    component: Marketplace,
    path: '/marketplace'
  },
  ProductDetail: {
    component: ProductDetail,
    path: '/product-detail'
  },
  ProductList: {
    component: ProductList,
    path: '/product-list'
  },
  ManageUserProduct: {
    component: ManageUserProduct,
    path: '/manage-your-product'
  },
  Directory: {
    component: Directory,
    path: '/directory'
  },
  Search: {
    component: Search,
    path: '/search'
  },
  ShareMap: {
    component: ShareMap,
    path: '/share-map'
  },
  FormSubmission: {
    component: FormSubmission,
    path: '/form-submission'
  },
  inspection: {
    component: Inspection,
    path: '/manage-inspection'
  },
  Visitor: {
    component: Visitor,
    path: '/manage-visitor'
  },
  VisitorForm: {
    component: FormSubmission,
    path: '/visitor-form'
  },
  StopDeviceAlarmRequest: {
    component: StopDeviceAlarmRequest,
    path: '/stop-device-alarm-request'
  },
  VerifyElapsedPage: {
    component: VerifyElapsedPage,
    path: "/sar-elapsed-time",
  },
};
