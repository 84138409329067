import _ from 'lodash';
import ManageAssets from './Icons/ManageAssets';
import ManageMob from './Icons/ManageMob';
import ManageProperties from './Icons/ManageProperties';
import ManageTasks from './Icons/ManageTasks';
import PropertyMap from './Icons/PropertyMap';
import PropertyArea from './Icons/PropertyArea';
import ManageLivestock from './Icons/ManageLivestock';
import DefaultProperty from './Icons/DefaultProperty';
import WeatherArea from './Icons/WeatherArea';
import marketplace from './Icons/marketplace.png';
import HealthSafety from './Icons/HealthSafety';
import React from 'react';
import Visitors from './Icons/Visitors';

export function options(properties, activeId = null, is_system_admin = null) {
  const options = (is_system_admin !== 1) ? 
  [
    {
      key: 'manage-properties',
      label: 'Properties',
      leftIcon: <ManageProperties />,
    },
    {
      key: 'map',
      label: 'Map',
      leftIcon: <PropertyMap />,
    },
    {
      key: 'manage-areas',
      label: 'Areas',
      leftIcon: <PropertyArea />,
    },
  
    { 
      key: 'health-safety',
      label: 'sidebar.healthSafety',
      leftIcon: <HealthSafety/>,
    },
    { 
      key: 'safe-farm',
      label: 'sidebar.safeFarm',
      leftIcon: <ManageAssets/>,
    },
    {
      key: 'weather',
      label: 'Weather',
      leftIcon: <WeatherArea/>,
    },
    {
      key: 'manage-visitor',
      label: 'sidebar.manageVisitor',
      leftIcon: <Visitors/>
    },
    {
      key: 'manage-assets',
      label: 'Assets',
      leftIcon: <ManageAssets/>,
    },
    {
      key: 'manage-tasks',
      label: 'Tasks',
      leftIcon: <ManageTasks/>,
    },
    {
      key: 'manage-mobs',
      label: 'Mobs',
      leftIcon: <ManageMob/>,
    },
    {
      key: 'manage-livestocks',
      label: 'LiveStocks',
      leftIcon: <ManageLivestock/>,
    },
   
   
   
    
  ] : [
    {
      key: 'admin-dashboard',
      label: 'sidebar.adminDashboard',
      leftIcon: <ManageTasks/>,
    },
    {
      key: 'manage-product-category',
      label: 'sidebar.manageProductCaregory',
      leftIcon: <ManageTasks/>
    },
    {
      key: 'manage-product',
      label: 'sidebar.manageProduct',
      leftIcon: <ManageTasks/>
    },
    {
      key: 'manage-property',
      label: 'sidebar.manageProperty',
      leftIcon: <ManageTasks/>
    },
    {
      key: 'manage-user',
      label: 'sidebar.manageUser',
      leftIcon: <ManageTasks/>
    },
    {
      key: 'manage-sample-avatar',
      label: 'sidebar.manageSampleAvatar',
      leftIcon: <ManageTasks/>
    },
    {
      key: 'manage-form',
      label: 'sidebar.manageForm',
      leftIcon: <ManageTasks/>
    },
    {
      key: 'category-form',
      label: 'sidebar.categoryForm',
      leftIcon: <ManageTasks/>
    },
    {
      key: 'manage-agent',
      label: 'sidebar.manageAgent',
      leftIcon: <ManageTasks/>
    }
  ]
  const activeProperty = JSON.parse(localStorage.getItem('active_property'));
  if(activeProperty && is_system_admin !== 1){
    options.unshift(
      {
        key: 'dashboard',
        label: activeProperty.name,
        leftIcon: <DefaultProperty/>,
      },
    );
  } 
    
  return options;
}