import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const QualificationModalWrapper = styled(Modal)`
.btn-success{
  background-color: #65bb38;
  border-color: #559f2f;
  &:hover{
    opacity: 0.8;
  }
}
.ant-modal-body{
  padding: 0;
}
.action-button{
  align-items: center;
  text-align: left;
  display: flex;
  gap: 8px !important;
  height:40px;
  width:100%;
}
.action-button .ant-upload.ant-upload-select.ant-upload-select-picture{
  width:100% !important;
  height  :40px;
}
.form-title{
  padding: 16px 24px;
  border-bottom: 1px solid #eaeef1;
  h3{
    font-size: 1rem;
  }
}
.form-body{
  padding: 12px 24px 12px 24px;
  .checkbox-contact{
    margin-bottom: 10px;
  }
}
&.mob-cannot-create .ant-btn-primary{
  display: none;
}
.btn-create-breed{
  background-color: #46AFCA;
  border-color: #46AFCA;
  &:hover{
    opacity: 0.8;
  }
  margin-top:30px;
  width: 100%;
  padding: 0;
}
.card_content{
  display:inline;
}

.card_value{
  float:right;
}

.image-gallery{
  padding: 5px;
}
`;

export default WithDirection(QualificationModalWrapper);