import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const ShareOptionsModalWrapper = styled(Modal)`
  .form-qr-area {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    .ant-typography {
      margin-top: 20px;
      padding: 5px;
      border: 1px solid #719E67;
      border-radius: 5px;
      svg {
        fill: #719E67;
      }
    }
  }
  .custSelect{
    width : auto !important;
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover{
      opacity: 0.8;
    }
  }
  .ant-modal-body{
    padding: 0;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  .btn-create-crop{
    background-color: #46AFCA;
    border-color: #46AFCA;
    &:hover{
      opacity: 0.8;
    }
    margin-top:30px;
    width: 100%;
    padding: 0;
  }
  .share-button{
    margin-right: 10px;
  }
`;

export default WithDirection(ShareOptionsModalWrapper);