
import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const ProfilePage = styled.div`
  

  width: 20%;
  padding:10px;
  border-radius : 8px;
min -height: 100%;
background-color: white;
// border-radius: 20px 20px 0px 0px;


.cutmCard{
  width: 100%;
  margin-top: 0px !important;
  margin-bottom:20px;
  display : flex;
  .ant-card-body{
    width:100%;
  }

flex-shrink: 0;
border-radius: 16px;
background: var(--primary-base, #5e8c53 );
    .MainDivCard{
      width:100%;
      display:flex;
      margin:10px;
    }
.loaderCutm{

  width: 64px;
height: 64px;
flex-shrink: 0;
fill: var(--greyscale-50, #FAFAFA);
}
.verifyBtn{
  color: #5e8c53;
  border : none !important;
  display: flex !important;
width: 100% !important;
height: 40px !important;
padding: 8px !important;
justify-content: center !important;
align-items: center !important;
gap: 10px !important;
flex-shrink: 0 !important;
border-radius: 8px !important;
background: var(--others-white, #FFFFFF) !important;
}
.loaderText{
  
  color: var(--others-white, #FFF);
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
}
.inforTitle{
  align-self: stretch;
  color: var(--others-white, #FFF);
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 150%; 
letter-spacing: 0.2px;
}
.cardDetails{
  align-self: stretch;
  color: var(--others-white, #FFF);
font-family: Manrope;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 18px */
letter-spacing: 0.4px;
}
}
  svg.brand-primary-light {
    color: rgb(248, 14, 70) !important;
}
.groip
  .dot-btn {
    span {
      rotate: 90deg;
      cursor: pointer;
    }
  }
  // .ant-popover-inner {
  //   border-radius: 12px !important;
  // }
  .ButtonGroup {
    text-align: center;
    display : grid; 
    .selected{
      color:#5e8c53;
     
align-item:center;
flex-shrink: 0;
border-radius: 8px;
background: #F7F9F6;
padding: 15px;
text-align:center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
    }
    .btn{
      border-radius: 8px;
margin-top:15px;
margin-bottom:15px;
flex-shrink: 0;
      color: var(--Black-Color, #323142);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;

    }
  }
  .manage-property-title {
    color: #101828;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .manage-property-table {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background: white;
    .ant-pagination {
      padding: 24px;
    }
    .add-property-btn {
      background: #f80e46;
      border-radius: 8px !important;
      color: #fff;
    }
    .m-p-main {
      padding: 24px;
      display: flex;
      justify-content: space-between;
    }
    .dropdown {
      border: 1px solid #d0d5dd !important;
    }
  }
  .action-btn {
    font-size: 12px;
    line-height: 24px;
    background: #535763;
    border-radius: 8px !important;
    border-color: transparent !important;
    color: white;
  }
  .button-group {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .fAgWDa .ant-table-content {
    overflow-x: unset;
  }
  .fAgWDa {
    overflow-x: unset;
  }
  .dtr-data {
    white-space: break-spaces;
  }
  .delete-button {
    margin-left: 5px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    .cutmCard .MainDivCard .content{
      width:100%;
  }
  .cutmCard .MainDivCard{
    display: flex !important;
   margin:20px  !important;
  }
  .cutmCard .MainDivCard .cardImg{
    margin-left:0px !important;
    
  }
  }
  @media only screen and (max-width: 768px) {
    width:100% ;
    margin-bottom:10px;
    .cutmCard .MainDivCard .content{
      width:100%;
      margin: 10px 0px 0px 25px;
  }
  .cutmCard .MainDivCard{
    display: flex !important;
   margin:20px  !important;
  }
  .cutmCard .MainDivCard .cardImg{
    margin-left:25px;
    
  }
  }
  @media only screen and (max-width: 1199px) {
   
    .cutmCard .MainDivCard .content{
        width:100%;
    }
    .cutmCard .MainDivCard{
      display: inline;
      margin-buttom:5px;
    }
    .cutmCard .MainDivCard .cardImg{
      margin-left:25px;
      
    }
  }
  @media only screen and (max-width: 1281px) {
   
    .cutmCard .MainDivCard .content{
        width:100%;
    }
    .cutmCard .MainDivCard{
      display: inline ;
      margin-buttom:5px;
    }
    .cutmCard .MainDivCard .cardImg{
      margin-left:25px;
      
    }
    .cutmCard .MainDivCard .content .inforTitle{
      font-size : 14px;
    }
  }
  @media only screen and (width: 1024px) {
    .ButtonGroup {
      text-align: center;
      
    }
    .cutmCard .MainDivCard .content{
        width:100%;
    }
    .cutmCard .MainDivCard{
      display: inline ;
      margin-buttom:5px;
    }
    .cutmCard .MainDivCard .cardImg{
      // margin-left:25px;
      
    }
    .cutmCard .verifyBtn {
    
      font-weight: 400;
    font-size: 12px ;
    }
    .cutmCard .MainDivCard .content .inforTitle{
      font-size : 14px;
    }
  }
  @media only screen and (max-width: 375px) {
   
    .cutmCard .MainDivCard .content{
        width:100%;
    }
    .cutmCard .MainDivCard{
      display: flex !important;
     margin:20px  !important;
    }
    .cutmCard .MainDivCard .cardImg{
      margin-left:0px;
      
    }
    .cutmCard .verifyBtn {
    
      font-weight: 400;
    font-size: 12px ;
    }
    .cutmCard .MainDivCard .content .inforTitle{
      font-size : 14px;
    }
  }
  
`;

export default WithDirection(ProfilePage);
