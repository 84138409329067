import React, { useState, useEffect, useRef } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "@iso/components/Tables/configs";
import { Button, Dropdown, Input, Menu, Pagination, Popconfirm } from "antd";
import MobManagePage from "./Mob.styles";
import {
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  mobService,
  propertyService,
  activityService,
  livestockService,
} from "@iso/services";
import modalActions from "@iso/redux/modal/actions";
import _ from "lodash";
import { objectColors } from "@iso/constants/objectColors";
import moment from "moment";
import MobModal from "@iso/containers/Pages/PropertyMap/components/Mob/MobModal";
import AddHistoryModal from "@iso/containers/Pages/PropertyMap/components/Mob/AddHistoryModal";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import SelectPropertyModal from "@iso/containers/Pages/Property/SelectPropertyModal";
import { CSVLink } from "react-csv";
import Loader from "@iso/components/utility/loader";
import MergeMobModal from "./MergeMobModal";
import inside from "point-in-polygon";
import * as turf from "@turf/turf";
import L from "leaflet";
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";

const { openModal } = modalActions;

const Mob = (props) => {
  const dispatch = useDispatch();
  const [mobs, setMobs] = useState([]);
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activeProperty = localStorage.getItem("active_property");
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [mobData, setMobData] = useState({});
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const [mobsForExport, setMobsForExport] = useState([]);
  const [joningReport, setJoningReport] = useState([]);
  const [calvingReport, setCalvingReport] = useState([]);
  const [visibleMergeMobModal, setVisibleMergeMobModal] = useState(false);
  const csvJoiningLink = useRef();
  const csvCalvingLink = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchInput, setsearchInput] = React.useState("");


  const [loadingData, setLoadingData] = React.useState(true);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedMobs = searchQuery?.slice(startIndex, endIndex);
  const displayData = props.search_result && props.search_result.Mob?.slice(startIndex, endIndex);
  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        setPrimaryObjects(res.primary_objects);
        setLoadingData(false);
      }
    });
  }, []);

  useEffect(() => {
    if (props?.handleMobs) {
      props?.handleMobs(mobs.length);
    }
  }, [mobs]);
  useEffect(() => {
    if (activePropertyId) {
      updatePropertyDetail(activePropertyId);
    }
  }, [activePropertyId, updatePropertyDetail, activeProperty]);

  useEffect(() => {
    if (joningReport.length > 0 && csvJoiningLink.current) {
      setTimeout(() => {
        csvJoiningLink.current.link.click();
        setJoningReport([]);
      });
    }
  }, [joningReport]);

  useEffect(() => {
    if (calvingReport.length > 0 && csvCalvingLink.current) {
      setTimeout(() => {
        csvCalvingLink.current.link.click();
        setCalvingReport([]);
      });
    }
  }, [calvingReport]);

  const updateMobData = () => {
    if (activePropertyId) {
      mobService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setMobs(res.mobs);
          setSearchQuery(res.mobs);
          setMobsForExport(res.mobs_for_export);
          setLoadingData(false);
        }
      });
    }
  };

  useEffect(() => {
    updateMobData();
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      }
    });
  }, [activePropertyId]);

  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  const handleMenuClick = (mob, e) => {
    if (e.key === "editDetails") {
      showModal(mob);
    }

    if (e.key === "addHistory") {
      setMobData(mob);
      showAddHistoryModal();
    }
  };

  const getDataReport = (id, type) => {
    mobService.getDataReportCSV(id).then((res) => {
      if (type === "joining") {
        setJoningReport(res.dataJ);
      } else {
        setCalvingReport(res.dataC);
      }
    });
  };
  const handleReportJoining = async (mob) => {
    const res = await getDataReport(mob.id, "joining");
  };

  const handleReportCalving = async (mob) => {
    const res = await getDataReport(mob.id, "calving");
  };

  const showModal = (object) => {
    const modalData = {
      object,
      type: "mob",
      canCreate: true,
    };
    dispatch(openModal(modalData));
  };

  const showAddHistoryModal = () => {
    setVisibleHistory(true);
  };

  const cancelModalHistory = () => {
    setVisibleHistory(false);
  };

  const showMergeMobModal = () => {
    setVisibleMergeMobModal(true);
  };

  const cancelMergeMobModal = () => {
    setVisibleMergeMobModal(false);
  };

  const menu = (mob) => (
    <Menu onClick={handleMenuClick.bind(this, mob)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {(activePermission == "owner" ||
        activePermission == "admin" ||
        activePermission == "modify") && (
        <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
          <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
        </Menu.Item>
      )}
      {(activePermission == "owner" || activePermission == "admin") && (
        <p style={{ cursor: "pointer" }} className="deleteButton">
          <Popconfirm
            placement="bottomRight"
            title="You are about to delete individual record"
            onConfirm={() => {
              confirmDelete(mob);
            }}
            okText="Yes"
            cancelText="No"
            className="delete-button"
          >
            <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
              {<DeleteOutlined />}&nbsp;&nbsp;
              <IntlMessages id="propertyPage.managePage.delete" />
            </a>
          </Popconfirm>
        </p>
      )}
    </Menu>
  );

  const menuReport = (mob) => (
    <Menu>
      <Menu.Item key="joiningReport">
        <Button
          type="primary"
          className="btn-success ant-btn-primary"
          style={{ borderColor: "#729D68", backgroundColor: "#729D68" }}
          onClick={handleReportJoining.bind(this, mob)}
        >
          Joining Report
        </Button>
      </Menu.Item>
      <Menu.Item key="calvingReport">
        <Button
          type="primary"
          className="btn-success ant-btn-primary"
          style={{ borderColor: "#729D68", backgroundColor: "#729D68" }}
          onClick={handleReportCalving.bind(this, mob)}
        >
          Calving Report
        </Button>
      </Menu.Item>
    </Menu>
  );

  const onSaved = (savedMob) => {
    let mobTmp = _.clone(mobs);
    const mobIndex = _.findIndex(mobTmp, (mob) => {
      return mob.id === savedMob.id;
    });
    if (mobIndex === -1) {
      mobTmp.push(savedMob);
    } else {
      mobTmp[mobIndex] = savedMob;
    }
    setMobs(mobTmp);
    setSearchQuery(mobTmp)
  };

  const confirmDelete = (del_mob) => {
    if (del_mob.id) {
      mobService.destroy(del_mob.id).then((res) => {
        if (res.code === "0000") {
          updateMobData();
        }
      });
    }
  };

  const columns = [
    {
      label: <IntlMessages id="antTable.title.name" />,
      accessor: "name",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="antTable.title.animal" />,
      accessor: "type_label",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="antTable.title.area" />,
      accessor: "primary_object",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="antTable.title.tag_colour" />,
      accessor: "tag_colour",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let mob = cell.row;
        return objectColors.map((value, index) => {
          const opacity = 0.3;
          const rgbValues = value.color.match(/\d+/g);
          const colorWithOpacity = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;
          if (value.value.toLowerCase() == mob.tag_colour.toLowerCase()) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  backgroundColor: colorWithOpacity,
                  padding: "5px 10px",
                  borderRadius: "10px",
                  gap: "6px",
                  maxWidth: "130px",
                  alignItems: "center",
                }}
              >
                <ColorItemPreview
                  style={{ backgroundColor: value.color }}
                ></ColorItemPreview>
                &nbsp;&nbsp;
                <div>{value.label}</div>
              </div>
            );
          }
        });
      },
    },
    {
      label: <IntlMessages id="propertyPage.modal.mob.numberAnimal" />,
      accessor: "tag_number_range",
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: <IntlMessages id="antTable.title.date_of_birth" />,
      accessor: "date_of_birth",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: (cell) => {
        let mob = cell.row;
        return mob.date_of_birth
          ? moment(mob.date_of_birth).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      label: <IntlMessages id="antTable.title.breed" />,
      accessor: "breed",
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
    },
    {
      label: "Report",
      accessor: "actions",
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8,
      noTitle: true,
      CustomComponent: (cell) => {
        let mob = cell.row;
        return (
          <div>
            <Dropdown overlay={menuReport(mob)} trigger={["click"]}>
              <Button
                className="btn-success ant-btn-primary"
                style={{ marginLeft: "5px" }}
              >
                Report <DownOutlined />
              </Button>
            </Dropdown>
            <CSVLink
              target="_blank"
              ref={csvJoiningLink}
              filename={
                "joining_report_list_" +
                moment().format("DD/MM/YYYY HH:mm:ss") +
                ".csv"
              }
              data={joningReport}
              className="hidden"
            />
            <CSVLink
              target="_blank"
              ref={csvCalvingLink}
              filename={
                "calving_report_list_" +
                moment().format("DD/MM/YYYY HH:mm:ss") +
                ".csv"
              }
              data={calvingReport}
              className="hidden"
            />
          </div>
        );
      },
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 9,
      priorityLevel: 9,
      noTitle: true,
      CustomComponent: (cell) => {
        let mob = cell.row;
        return (
          <Dropdown overlay={menu(mob)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);
  };

  const getLatLngMarkerArea = (number, objectPrimary, arrayData) => {
    const points = [];
    const coordinates = listAllMarkerArea(objectPrimary);
    if (arrayData.length > 0) {
      _.forEach(coordinates, function (point) {
        if (!_.find(arrayData, point) && points.length < number) {
          points.push(point);
        }
      });
    } else {
      points.push(coordinates[0]);
    }
    return points;
  };

  const listAllMarkerArea = (object) => {
    const polygon = L.polygon([object.area]);
    const bounds = polygon.getBounds();

    var y_min = polygon.getBounds().getWest();
    var y_max = polygon.getBounds().getEast();
    var x_min = polygon.getBounds().getSouth();
    var x_max = polygon.getBounds().getNorth();
    const cellSide = 0.05;
    const options = { units: "kilometers" };
    const extent = [y_min, x_min, y_max, x_max];
    const grid = turf.pointGrid(extent, cellSide, options);

    let positionMarker = [];
    var area = polygon.getLatLngs()[0].map(function (point) {
      return [point.lat, point.lng];
    });
    _.forEach(grid.features, function (point) {
      if (
        inside(
          [point?.geometry?.coordinates[1], point?.geometry?.coordinates[0]],
          area
        ) &&
        point?.geometry?.coordinates[1] < object.lat_centroid
      ) {
        positionMarker.push({
          lat: point?.geometry?.coordinates[1].toFixed(8),
          lng: point?.geometry?.coordinates[0].toFixed(8),
        });
      }
    });

    // horizontally sort : lat
    positionMarker.sort(function (a, b) {
      if (a.lat < b.lat) return -1;
      if (a.lat > b.lat) return 1;
      if (a.lat == b.lat) {
        if (a.lat > b.lat) return -1;
        else return 1;
      }
    });

    // reverse array
    if (x_min > 0 && x_max > 0) {
      positionMarker = [...positionMarker].reverse();
    }

    // arrange middle in array
    let xHorizontal = positionMarker.filter(
      (x) => x.lat == positionMarker[0].lat
    );

    let xMiddle = xHorizontal[Math.round((xHorizontal.length - 1) / 2)];
    let yAllMiddle = positionMarker.filter((y) => y.lng == xMiddle.lng);
    let YMiddle = yAllMiddle[Math.round((yAllMiddle.length - 1) / 2)];
    let xUnderName = positionMarker.filter((x) => x.lat == YMiddle.lat);

    let xMarkerSort = [];
    _.forEach(xUnderName, function (pM, i) {
      // get middle item
      xMiddle = xUnderName[Math.round((xUnderName.length - 1) / 2)];

      // get index middle item
      var indexMiddle = _.findIndex(xUnderName, xMiddle);
      if (!_.find(xMarkerSort, xMiddle)) {
        xMarkerSort.push(xMiddle);
        xUnderName.splice(indexMiddle, 1);
      }
    });

    return xMarkerSort;
  };
  const handleCancelSearch = () => {
    setsearchInput("");
    setSearchQuery(mobs);
  };
  const handleSearch = (e) => {
    setsearchInput(e.target.value);
    const searchTerm = e.target.value.toLowerCase();
    const filteredAssets = mobs.filter((mob) => {
      return (
        mob.name.toLowerCase().includes(searchTerm) ||
        mob.type_label.toLowerCase().includes(searchTerm) ||
        mob.primary_object.toLowerCase().includes(searchTerm) ||
        mob.tag_colour.toLowerCase().includes(searchTerm) ||
        mob.tag_number_range.toString().includes(searchTerm) ||
        (mob.date_of_birth &&
          moment(mob.date_of_birth)
            .format("DD/MM/YYYY")
            .includes(searchTerm)) ||
        mob.breed.toLowerCase().includes(searchTerm)
      );
    });
    setSearchQuery(filteredAssets);
    setCurrentPage(1); // Reset the current page to 1 when the search term changes
  };
  
  
  return (
    <LayoutWrapper>
      <MobManagePage>
        <Box id="containerBox">
          <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Mobs</h2>
            </div>
            {props.search_result ? (
              ""
            ) : (
              <>
            <Input
              placeholder="Search…"
              suffix={
                !searchInput && (
                  <SearchOutlined className="site-form-item-icon" />
                )
              }
              prefix={
                searchInput && (
                  <CloseCircleOutlined
                    onClick={handleCancelSearch}
                    className="site-form-item-cancel"
                  />
                )
              }
              size="large"
              className="search-bar"
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
            <div className="button-group">
              {propertyHelper.checkIsGoldPackage(activePropertySubscription) && (
                <CSVLink
                  filename={
                    "mob_list_" +
                    moment().format("DD/MM/YYYY HH:mm:ss") +
                    ".csv"
                  }
                  data={mobsForExport}
                >
                  <Button type="primary" className="btn-download">
                    <IntlMessages id="mobPage.managePage.download" />
                  </Button>
                </CSVLink>
              )}
              &nbsp;
              <Button
                type="primary"
                className="btn-merge"
                onClick={showMergeMobModal}
              >
                <IntlMessages id="mobPage.managePage.mergeMob" />
              </Button>
              &nbsp;
              {(activePermission == "owner" || activePermission == "admin") && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  className="btn-success"
                  onClick={showModal}
                >
                  <IntlMessages id="mobPage.managePage.addMob" />
                </Button>
              )}
            </div>
            </>)}
          </div>
          {loadingData ? (
            <Loader />
          ) : (
            <>
              {props.search_result &&
              props.search_result.Mob &&
              props.search_result.Mob.length > 0 ? (
                <>
                <div className="manage-table">
                  <Table
                    columns={columns}
                    rows={displayData}
                    containerId="containerBox"
                    showPagination={false}
                    rowSize={99999}
                  />
                </div>
                {props.search_result.Mob?.length > 10 &&
                  <div style={{ padding: "20px" }}>
                    <Pagination
                      current={currentPage}
                      total={props.search_result.Mob?.length}
                      pageSize={pageSize}
                      onChange={handlePageChange}
                      showSizeChanger
                      pageSizeOptions={["10", "20", "50", "100"]}
                      onShowSizeChange={handlePageSizeChange}
                    />
                  </div>}</>
              ) : (
                <div className="manage-table">
                  <>
                    {!props.search_result && searchQuery && searchQuery.length > 0 ? (
                      <>
                        <Table
                          columns={columns}
                          rows={displayedMobs}
                          containerId="containerBox"
                          showPagination={false}
                          rowSize={99999}
                        />
                        <div style={{ padding: "16px" }}>
                          <Pagination
                            current={currentPage}
                            total={searchQuery?.length}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                            showSizeChanger
                            pageSizeOptions={["10", "20", "50", "100"]}
                            onShowSizeChange={handlePageSizeChange}
                          />
                        </div>
                      </>
                    ) : (
                      <h1 style={{ padding: "15px 15px",color:'gray' }}>
                        <IntlMessages id="dashboardPage.noData" />
                      </h1>
                    )}
                  </>
                </div>
              )}
            </>
          )}
        </Box>
        <MobModal
          onSaved={onSaved}
          updateDataMobs={updateMobData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          updateMobData={updateMobData}
          getLatLngMarkerArea={getLatLngMarkerArea}
        />

        <AddHistoryModal
          visibleHistory={visibleHistory}
          updateDataMobHistory={updateMobData}
          model="mobs"
          type="mob"
          dataId={mobData.id}
          primaryObjects={primaryObjects}
          cancelModal={cancelModalHistory}
          propertyId={activePropertyId}
          mobHistory={true}
          numberAnimals={mobData.tag_number_range}
          updateNumberAnimal={updateMobData}
          afterArchived={updateMobData}
        />
      </MobManagePage>

      <SelectPropertyModal
        visibleSelectProperty={visibleSelectProperty}
        cancelSelectPropertyModal={cancelSelectPropertyModal}
      />
      <MergeMobModal
        visibleMergeMobModal={visibleMergeMobModal}
        cancelMergeMobModal={cancelMergeMobModal}
        mobs={mobs}
        updateMobData={updateMobData}
      />
    </LayoutWrapper>
  );
};

export default Mob;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div {
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
