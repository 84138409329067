import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const WeatherForecastChartWrapper = styled.div`
  .weather-statistic{
    .statistic-header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      @media only screen and (max-width: 560px) {
        display: block;
      }
    }
    .weather-types{
      display: flex;
      overflow: auto
    }
    .weather-type-icon{
      margin-right: 5px
    }
    .weather-type-element{
      cursor: pointer;
      padding: 5px;
      display: flex;
      align-items: center;
      width: fit-content;
      margin-right: 10px;
      min-width: 140px;
      &:hover {
        color:#729D68;
      }
    }
    .weather-type-active{
      border-bottom: 2px solid #729D68;
      * {
        color: #729D68
      }
    }
    .timezone-by{
      display: flex;
      align-items: center;
      p{
        margin-right: 5px;
      }
    }
  }
  .weather-category{
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;

export default WithDirection(WeatherForecastChartWrapper);
