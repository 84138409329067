import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const PrimaryObjectModalWrapper = styled(Modal)`
  .btn-success{
    width:50%;
    height:auto;
    background-color: #5e8c53;
    border-color: #5e8c53;
    &:hover{
      opacity: 0.8;
    }
  }
  .delete-button{
    width : 50%;
  }
  .ant-modal-body{
    padding: 0;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  .activeClass{
    font-size: 13px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
  }
  .btn-create-crop { 

    height: auto;
    padding: 7px;

border-radius: 8px;

    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    background: #ECDD5D;
    color:black;
    border-color: #ECDD5D;
    &:hover{
      opacity: 0.8;
    }
    margin-top:30px;
    width: 100%;
   
  }
`;

export default WithDirection(PrimaryObjectModalWrapper);