import React,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@iso/components/utility/box';
import {Button,Radio} from 'antd';
import ReactFC from "react-froalacharts";
import FroalaCharts from "froalacharts";
import FroalaTheme from "froalacharts/themes/froalacharts.theme.froala";
import _ from 'lodash';
import DeviceChartWapper from './DeviceChart.styles'; 
import IntlMessages from '@iso/components/utility/intlMessages';

ReactFC.fcRoot(FroalaCharts, FroalaTheme);

const DeviceChart = (props) => {

  const [dataChart, setDataChart] = useState(props.assetChartData);
  const [tabKey, setTabKey] = useState(1);
  useEffect(() => {
    if(props.assetChartData && props.historyChartData){
      if(tabKey == 1){
        setDataChart(props.assetChartData);
      }else if(tabKey == 2){
        setDataChart(props.historyChartData);
      }
    }
  },[tabKey,props.assetChartData,props.historyChartData]);

  const chartConfig = {
    type: 'column',
    renderAt: 'safe_farm_chart_data',
    width: '100%',
    height: '300',
    dataSource:
    {
      chart:
      {
        theme: "froala",
        paletteColors: "#65BB38",
        xAxisname: "Type",
        yAxisName: "Value",
        plotFillAlpha: "80",
        divLineIsDashed: "1",
        divLineDashLen: "1",
        exportEnabled: "1",
        divLineGapLen: "1"
      },
      categories: [
      {
        category: dataChart[0],
      }],
      dataset: [
      {
        data: dataChart[1],
      }]
    }
  };
  
  return (
    <DeviceChartWapper>
      <Radio.Group value={tabKey} onChange={(e) => setTabKey(e.target.value)}>
        <Radio.Button value={1}><IntlMessages id="healthSafety.history.assetName" /></Radio.Button>
        <Radio.Button value={2}><IntlMessages id="propertyPage.mobModal.historyTab" /></Radio.Button>
      </Radio.Group>
      <ReactFC {...chartConfig} />
    </DeviceChartWapper>
   
  );
}

export default DeviceChart;