import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const PreviewTemplateWrapper = styled(Modal)`
  .preview-form-area {
    border: solid 1px gray;
  }
  .btn-success{
      background-color: #65bb38!important;
      border-color: #559f2f!important;
  }
  .ant-modal-body{
    padding: 0px !important
  }
`;

export default WithDirection(PreviewTemplateWrapper);