import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const DeviceChartWrapper = styled.div`

.ant-radio-button-wrapper-checked,.ant-radio-button-wrapper:hover{
  color: #729D68 !important;
  border-color:#729D68 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #729D68;
}

`;
export default WithDirection(DeviceChartWrapper);