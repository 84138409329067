import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const DirectoryManagePage = styled.div`
  h2 {
    font-size: 2em;
  }

  .directory-container{
    background-color: white;
    padding: 15px;
  }

  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
  }

  .button-group{
  	padding: 15px;
  }

  .alphabet-scroll-bar{
    width: 5vh;
    position: fixed;
    right: 2vh;
    top: 15vh;
    z-index: 99;

    li {
      background-color: #E9EBED;
      border-color: #559f2f;
      padding: 0.5vh 2vh;
      text-align: center;
      cursor: pointer;
      font-size: 1.4vh;
    }
  }
`;

export default WithDirection(DirectoryManagePage);