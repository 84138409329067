import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const ListNotificationWrapper = styled.div`
  width:100%;
  position: absolute;
  width: 300px;
  max-height: 500px;
  overflow: auto;
  top: 100%;
  right: 10%;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #729D68;
  z-index: 1;
  @media(max-width: 767px){
    width:300px;
    right: auto;
    left: -150px;
  }
  .ant-btn{
    background-color: #729D68;
    color: #ffffff;
    font-size: 12px;
    padding: 5px 7px;
    &:hover{
      background: #ffffff;
      color: #729D68;
    }
  }
  
  .notification-data-item{
    margin-bottom: 15px;
    border-bottom: 1px solid #729D68;
    padding-bottom: 10px;
    &:last-child{
      padding-bottom: 0px;
      border-bottom: 0px;
      margin-bottom: 0;
    }
    h3{
      color: #729D68;
      margin-bottom: 10px;
    }
    .notification-body{
      margin-top: 10px;
    }
    .notification-date{
      margin-top:5px;
      i{
        font-size: 12px !important;
       }
    }
  }
`;

export default WithDirection(ListNotificationWrapper);
