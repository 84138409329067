import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const FormModalWrapper = styled(Modal)`
border-radius:0px !important
textarea#weather_data_comment{
  border-color : #dbdbdb !important
}
.cancel-button{
  border-radius: 8px;
  background-color: #ABABAB !important;
  border: #0000;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  line-height: 24px;
  padding: 8px;
  text-align: center;
  width: 50%;
}
.add-button{
  width: 50%;
  background-color: #5E8D54 !important;
  padding: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: rgb(255, 255, 255) !important;
  text-align: center;
  height: auto;
  border: transparent;
}
.ant-input-number.ant-input-number-lg.ant-input-number-in-form-item {
  border-color: #dbdbdb !important;
}
&.ctm-form-modal {
  width:1000px;


  hr{ 
    visibility: hidden;
  }
  .cancel-button{
    width:100% !important;
  }
  
}

}
  &.user-access-modal{
    width: 1200px!important;
  }
  .ant-modal-body{
    padding: 0;
  }

  label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
    display: flex;
}
  .sc-BQMaI{
    display:inline-block;
    width:100%;
    
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }

  .signature-area canvas{
    border: solid 1px black;
    max-width: 100%;
  }

  .signature-image {
    width: 100%;
  }

  .signature-clear-button {
    display: block;
  }

  .form-qr-area {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    .ant-typography {
      margin-top: 20px;
      padding: 5px;
      border: 1px solid #f80e46;
      border-radius: 5px;
      svg {
        fill: #f80e46;
      }
    }
  }

  .btn-view-form {
    margin-bottom:15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  span.ant-checkbox-inner {
    border: 1px solid #d9d9d9 !important;
    &:hover {
      border: 1px solid #5e8c53 !important;
    }
}

.ant-checkbox-checked .ant-checkbox-inner  {
  border-color:#5e8c53 !important;
  background-color: #5e8c53 !important;
}
.form-qr-area {
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: center;
  .ant-typography {
    margin-top: 20px;
    padding: 5px;
    border: 1px solid #5e8c53;
    border-radius: 5px;
    svg {
      fill: #5e8c53;
    }
  }
}
`;

export default WithDirection(FormModalWrapper);