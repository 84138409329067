import styled from 'styled-components';
import { palette } from 'styled-theme';
import WithDirection from '@iso/lib/helpers/rtl';
import { handleShowRandomImage } from '@iso/constants/randomBackgrounds';

const SignUpStyleWrapper = styled.div`
  

  #term-and-condition{
    color: #729D68
  }
  .spaceClass{
    height : 124px;
  }
  .termCondition{
    font-size: 14px !important;
    font-weight: 500  !important;
    line-height: 20px !important;
    letter-spacing: 0em
    text-align: left;
  }
  .butGroup{
    border-left: 3px solid #F7F7F7;
    display: flex;
    gap: 12px;
    width: 407px;
    flex-direction: column;
 
    left: 50px;
  }
  .continuMainDiv{
    display: flex;
    gap: 10px;
    width: 407px;
    flex-direction: column;
 
    left: 50px;
  }
  .continue{
    margin :50px 0px 0px 50px;
  }
  .continueBtn{
    background: #F7F7F7;
    width: 117px !important;
    height: 56px !important;
    top: 405px;
    left: 1223px;
    border-radius: 16px;
    margin-left: 50px;
  }
  .btn-success{
    background-color: #5E8D54;
    border-color: #5E8D54;
   
    border-radius: 8px;
   
    &:hover{
      opacity: 0.8;
    }
  }


  .signInClass{
  gap: 5px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
  .signin-inputlabel {
    // font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: #3f3f44;
  }
  .ant-form-item-label > label{
    color:#3F3F44 ;
    font-weight: 600
  }

  .password-placeholder > input {
    // background-color: transparent;
    background: #F7F7F7;
    font-size: 14px;
  }

  .signin-placeholder {
    // font-family: "Work Sans";
    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 58px;
    background: #F7F7F7 !important;
    border-radius: 8px;
    border: none;
  }
  .ant-input {
      &::placeholder {
        color: #3F3F444D;
        !important;
      }
    }

  .signin-btn {
    background: #5e8c53;
    border-radius: 8px !important;
    font-family: "Work Sans", sans-serif;
    span {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }

  .signin-heading {
    font-family: "Work Sans", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    color: #3f3f44;
  }

  .signin-heading-hi {
    font-family: "Work Sans", sans-serif !important;
    color: #3f3f44;
    font-style: normal;
    font-weight: 100 !important;
    font-size: 48px;
    line-height: 72px;
    display: flex;
    padding: 0 0 38px 0;
  }

  .signin-span {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3f3f44;
  }

  .signup-btn {
    font-family: Work Sans;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
  }

  .isoSignUpContentWrapper {
  width:100%;
    height: 100%;
    // overflow-y: auto;
    z-index: 10;
    position: relative;
    &.w-unset{
      width: unset;
    }
  }

  .isoSignUpContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px 50px;
    position: relative;
    background-color: #ffffff;
    justify-content: center;
    
    .showMsg{
      .icon-content{
        a{
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .text-content{
        margin-top: 15px;
        .msg-helper{
          margin-top: 35px;
          p{
            font-size: 14px;
            color: ${palette('text', 2)};
            margin-bottom: 10px;

            &.link-resend{
              color: #000;
              cursor: pointer;
              text-decoration: underline;
              &:hover {
                color: ${palette('text', 2)};
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 70px 20px;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      padding-bottom: 50px;
      justify-content: center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette('secondary', 2)};
      }
    }

    .isoSignUpForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      .ant-form-item-label > label{
        color: rgb(63, 63, 68) ;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before,
      .form-field label::after {
        content: unset;
      }
      .ant-form-item-label {
        font-family: "Work Sans", sans-serif;
       
       
        label {
          font-family: "Work Sans", sans-serif;
        }
      }
      .form-field > div {
        display: unset;
      }

      .isoInputWrapper {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &:-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &::-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }
          &:-ms-input-placeholder {
            color: ${palette("grayscale", 0)};
          }
        }
      }

      .isoHelperText {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: ${palette("grayscale", 1)};
        padding-left: ${(props) =>
          props["data-rtl"] === "rtl" ? "inherit" : "13px"};
        padding-right: ${(props) =>
          props["data-rtl"] === "rtl" ? "13px" : "inherit"};
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: "*";
          color: ${palette("error", 0)};
          padding-right: 3px;
          font-size: 14px;
          line-height: 1;
          position: absolute;
          top: 2px;
          left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
        }
      }

      .isoHelperWrapper {
        margin-top: 35px;
        flex-direction: column;
      }

      .isoOtherLogin {
        padding-top: 40px;
        margin-top: 35px;
        border-top: 1px dashed ${palette("grayscale", 2)};

        > a {
          display: flex;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        button {
          width: 100%;
          height: 42px;
          border: 0;
          font-weight: 500;

          &.btnFacebook {
            background-color: #3b5998;

            &:hover {
              background-color: darken(#3b5998, 5%);
            }
          }

          &.btnGooglePlus {
            background-color: #dd4b39;
            margin-top: 15px;

            &:hover {
              background-color: darken(#dd4b39, 5%);
            }
          }

          &.btnAuthZero {
            background-color: #e14615;
            margin-top: 15px;

            &:hover {
              background-color: darken(#e14615, 5%);
            }
          }

          &.btnFirebase {
            background-color: ${palette("color", 5)};
            margin-top: 15px;

            &:hover {
              background-color: ${palette("color", 6)};
            }
          }

          &.btnAccountKit {
            ${"" /* background-color: rgb(150, 189, 235); */}
            margin-top: 15px;

            &:hover {
              ${"" /* background-color: ${palette('color', 6)}; */}
            }
          }
        }
      }

      .isoForgotPass {
        font-size: 12px;
        color: ${palette("text", 3)};
        margin-bottom: 10px;
        text-decoration: none;

        &:hover {
          color: ${palette("primary", 0)};
        }
      }

      button {
        width: 100%;
        height: 42px;
        border: 0;
        font-weight: 500;
      }
    }
    .showMsg {
      text-align: center;
    }
  }
  @media only screen and (max-width: 1024px) {
    .signInmain{
      width : 100%;
      max-height: 100vh;
      .signIncol .img{
        max-width : 100vh;
        max-height: 700px
      }
    }
  .mainFromDiv{
      width: 100%;

    }
    .mainFromDiv .fromCol{
      width:100vh
    }
  }
  @media only screen and (max-width: 768px) {
    .signInmain{
      max-width : 100%;
      max-height: 100%;
      .signIncol .img{
        max-width : 100%;
      }
    }
  .mainFromDiv{
      width: 100%;
    }
    .mainFromDiv .fromCol{
      max-width:100%
    }
  }
`;

export default WithDirection(SignUpStyleWrapper);
