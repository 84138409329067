import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const ModalCategoryFormWrapper = styled(Modal)`
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #5e8c53;
    border-color: #5e8c53;    
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state{
    color: #fff !important;
  }

  .ant-switch{
    margin-top: 20px;
  }

  .ant-modal-body{
    padding: 12px 24px 12px 24px;

  }
`;

export default WithDirection(ModalCategoryFormWrapper);