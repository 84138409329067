import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const EditFieldModalWrapper = styled(Modal)`
  .preview-form-area {
    border: solid 1px gray;
    padding: 20px;
  }
  .btn-success{
      background-color: #65bb38!important;
      border-color: #559f2f!important;
  }
`;

export default WithDirection(EditFieldModalWrapper);