import React, {useState, useEffect } from 'react';
import DeviceTableWrapper from './DeviceTable.styles'; 
import { Row, Col, Button, Input, Space, Select, Dropdown, Menu, Popconfirm, Form, Alert, Switch } from 'antd';
import { iotDevice } from '@iso/services';
import _ from 'lodash';
import IntlMessages from '@iso/components/utility/intlMessages';
import { PlusOutlined, DownOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined, UserOutlined, PaperClipOutlined, SettingOutlined, SoundOutlined, SearchOutlined } from '@ant-design/icons';
import AssignModal from './AssignModal';
import DeviceTimeModal from './DeviceTimeModal';
import StopDeviceAlarmRequestModal from './StopDeviceAlarmRequestModal';
import Table from "@iso/components/collapsing-table/table";
import Box from "@iso/components/utility/box";
const { Option } = Select;

const DeviceTable = (props) => {
  const [dataDevice, setDataDevice] = useState([]);
  const [dataDeviceByProperty, setDataDeviceByProperty] = useState([]);
  const [deviceId, setDeviceId] = useState();
  const [loading, setLoading] = useState(false);
  const propertyId = props.propertyId;
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [isSaveAssign, setIsSaveAssign] = React.useState(false);
  const [assignedDevice, setAssignedDevice] = useState();
  const [isShowAssignModal, setIsShowAssignModal] = useState(false);
  const [isShowDeviceTimeModal, setIsShowDeviceTimeModal] = useState(false);
  const [isVisibleStopAlarmRequestModal, setIsVisibleStopAlarmRequestModal] = useState(false);
  const [stopAlarmRequests, setStopAlarmRequests] = useState();

  useEffect(() => {
    if (propertyId) {
      getDeviceByProperty();
      getDevice();
    }
  }, [propertyId, isSaveAssign]);

  const getDevice = () => {
    iotDevice.getList().then((res) => {
      if (res.code === "0000") {
        setDataDevice(res.devices);
      }
    });
  };

  const getDeviceByProperty = () => {
    iotDevice.getList(propertyId).then((res) => {
      if (res.code === "0000") {
        setDataDeviceByProperty(res.devices);
      } else {
        setDataDeviceByProperty([]);
      }
    });
  };

  const searchDevice = (s) => {
    iotDevice.search(propertyId, s).then((res) => {
      if (res.code === "0000") {
        setDataDeviceByProperty(res.devices);
      }
    });
  };

  const handleChangeDevice = (value) => {
    setDeviceId(value);
  };

  const cancelStopAlarmModal = () => {
    setIsVisibleStopAlarmRequestModal(false)
    setStopAlarmRequests([])
  }
  const onAddDevice = (name) => {
    setLoading(true);
    setShowAlert(false);
    form
      .validateFields()
      .then((values) => {
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };
  const onStore = (value) => {
    const data = {
      name: value.name,
      property_id: propertyId,
    };
    iotDevice.storeOrUpdate(data).then((res) => {
      if (res.code === "0000") {
        getDeviceByProperty();
        getDevice();
        setDeviceId();
        props.getPositionDevice();
        setFields([
          {
            name: "name",
            value: "",
          },
        ]);
        setLoading(false);
        setMessage(res.message);
      } else {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        setMessage(res.message);
        setLoading(false);
      }
    });
  };
  const onSearchDevice = (e) => {
    if (e.target.value.length > 0) {
      searchDevice(e.target.value);
    } else {
      getDeviceByProperty();
    }
  };

  const renderDeviceSelect = () => {
    let options = [];
    let deviceName = [];
    _.forEach(dataDevice, (device, index) => {
      options.push(
        <Option label={device.name} value={device.device_id}>
          <div className="demo-option-label-item">{device.name}</div>
        </Option>
      );
      deviceName.push(device.name);
    });
    return (
      <Select
        mode="multiple"
        className="ant-select-device"
        placeholder="Select Devices"
        optionLabelProp="label"
        onChange={handleChangeDevice}
        value={deviceId}
        size="large"
        style={{ width: "100%" }}
        allowClear
      >
        {options}
      </Select>
    );
  };

  const menu = (iotDevice) => (
    <div>
      <Menu>
        <Menu.Item key="removeDevice">
          <Popconfirm
            // className="ant-popconfirm"
            placement="bottomRight"
            title="You are about to remove individual record"
            onConfirm={() => {
              confirmRemove(iotDevice.id);
            }}
            okText="Yes"
            cancelText="No"
            className="delete-button"
          >
            <a>
              {<DeleteOutlined />}&nbsp;&nbsp;
              <IntlMessages id="safeFarmPage.table.remove" />
            </a>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item
          key="assignUser"
          icon={<UserOutlined />}
          onClick={(e) => handleAssignUser(iotDevice)}
        >
          <IntlMessages id="safeFarmPage.table.assign" />
        </Menu.Item>
        <Menu.Item
          key="deviceTime"
          icon={<SettingOutlined />}
          onClick={(e) => handleSetDeviceTime(iotDevice)}
        >
          <IntlMessages id="safeFarmPage.table.deviceTime" />
        </Menu.Item>
        <Menu.Item key="showStopAlarmRequest" icon={<SoundOutlined />} onClick={(e)=>handleShowStopAlarmRequests(iotDevice?.stop_device_alarm_requests)}>
          <IntlMessages id="safeFarmPage.table.stopAlarmRequests" />
        </Menu.Item>
    </Menu>
    </div>
  );

  const handleShowStopAlarmRequests = (stopRequests) => {
    setIsVisibleStopAlarmRequestModal(true);
    setStopAlarmRequests(stopRequests);
  }
  const confirmRemove = (id) => {
    iotDevice.destroy(id).then((res) => {
      if (res.code === "0000") {
        getDeviceByProperty();
        getDevice();
        props.getPositionDevice();
      }
    });
  };
  const onChangeAlarmStatus = (id, status) => {
    iotDevice.updateAlarmStatus(id, status).then(()=>{
      getDeviceByProperty()
    })
  }

  const columnsTable = [
    {
      label: <IntlMessages id="safeFarmPage.table.name" />,
      accessor: "name",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        return (
          <span>
            {cell.row.name} <b>{cell.row.user?.name && `(${cell.row.user?.name})`}</b>
          </span>
        );
      }
    },
    {
      label: <IntlMessages id="Unique_ID" />,
      accessor: "unique_id",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: 'Alarm Status',
      accessor: 'alarm_status',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: (cell) => {
        let asset = cell.row;
        const checked = asset.is_trigger_alarm === 1 ? true : false;
        return (
          <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              onChange={() => onChangeAlarmStatus(asset.id, !asset.is_trigger_alarm)}
              defaultChecked={checked}
            />
        )
      },
    },
    {
      label: "Battery Level",
      accessor: "battery_level",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let record = cell.row;
        
        return (
          <div className='battery-level'>
            {record.battery_level}%
          </div>
        );
      }
    },
    {
      label: "Actions",
      accessor: "",
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      noTitle: true,
      CustomComponent: (cell) => {
        let record = cell.row;
        return (
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <Button className="actionmenu">
              <span>
                <IntlMessages id="antTable.title.actions" />
              </span>{" "}
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const cancelModal = () => {
    setIsShowAssignModal(false);
    setAssignedDevice({});
  };

  const cancelDeviceTimelModal = () => {
    setIsShowDeviceTimeModal(false);
    setAssignedDevice({});
  };

  const onSaveAssign = () => {
    setIsSaveAssign(!isSaveAssign);
  };

  const handleAssignUser = (device) => {
    setAssignedDevice(device);
    setIsShowAssignModal(true);
  };

  const handleSetDeviceTime = (device) => {
    setAssignedDevice(device)
    setIsShowDeviceTimeModal(true)
  }

  return (
    <DeviceTableWrapper>
    <Box id="containerBox">
      <Row gutter={[10, 15]}>
        {/* <div style={{display:'flex'}}> */}
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          {showAlert && (
            <Alert
              message="Error"
              description={message}
              type="error"
              closable
            />
          )}
        </Col>
        <Col>
          {" "}
          <h1 className="title">
            <IntlMessages id="safeFarmPage.device" />
          </h1>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }} className="columnCust">
          <Input
            className="ant-device-search"
            placeholder="Search Name"
            allowClear
            onChange={onSearchDevice}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 10 }} >
          <Form
            form={form}
            style={{marginLeft:'10px'}}
            name="addDevice"
            layout="inline"
            scrollToFirstError
            fields={fields}
          >
            <Form.Item
              name="name"
              // className="ant-device-search"
              rules={[
                {
                  required: true,
                  message: "Please input your device name",
                },
                {
                  whitespace: true,
                  message: "Please input your name",
                },
              ]}
            >
              <Input placeholder="Device Name" className="ant-device-search" />
            </Form.Item>
            <Button
              onClick={onAddDevice}
              loading={loading}
              className="ant-btn-add-device"
              icon={<PlusOutlined />}
            >
              <IntlMessages id="propertyMapPage.add" />
            </Button>
          </Form>
        </Col>
        {/* </div> */}
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <div className="manage-table">
          {dataDeviceByProperty && dataDeviceByProperty.length > 0 ?
            <Table
              rows={dataDeviceByProperty}
              columns={columnsTable}
              size="small"
              pagination={{ pageSize: 12 }}
              rowSize={99999}
                containerId="containerBox"
              bordered
              className="manage-table"
              rowClassName="rowClassName1"
            />
            :
            <h1 style={{ padding: "15px 15px 15px 10px",fontSize:'16px', color: "gray" }}>
                      <IntlMessages id="No data Found" />
                    </h1>
          }
          </div>
        </Col>
      </Row>
</Box>
      <AssignModal propertyId={propertyId} assignedIotDevice={assignedDevice} visible={isShowAssignModal} cancelModal={cancelModal} onSaved={onSaveAssign}/>
      <DeviceTimeModal propertyId={propertyId} assignedIotDevice={assignedDevice} visible={isShowDeviceTimeModal} cancelModal={cancelDeviceTimelModal} onSaved={onSaveAssign}/>
      <StopDeviceAlarmRequestModal stopAlarmRequests={stopAlarmRequests} visible={isVisibleStopAlarmRequestModal} cancelModal={cancelStopAlarmModal}/>
    </DeviceTableWrapper>
  );
};

export default DeviceTable;
