import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const GetReadyWrapper = styled.div`
  .image-section {
    @media only screen and (max-width: 767px) {
      height: 300px !important;
    }
  }
  .text-title {
    line-height: normal;
    margin-bottom: 32px;
  }
  .description {
    p:first-child,
    p:nth-child(2),
    p:nth-child(3) {
      margin-bottom: 24px;
    }
  }
  .button-group {
    display: flex;
    button {
      font-weight: 600;
    }
  }
  .plan-card {
    position: absolute;
    top: 12%;
    left: 25%;
    width: 260px;
    border-radius: 24px;
    background: #0a2504;
    padding: 24px 24px 31px 24px;
    @media only screen and (max-width: 992px) {
      top: 3%;
      left: 25%;
      width: 326px;
      padding: 12px 12px 23px 24px;
    }
    @media only screen and (max-width: 767px) {
      top: 6%;
      left: 0;
      width: 288px;
      padding: 12px 12px 23px 24px;
    }
    p {
      color: white;
    }
    .plan-name {
      font-size: 32px;
      font-weight: 700;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .description {
      font-size: 18px;
      line-height: 24px;
      margin-top: 16px;
      @media only screen and (max-width: 992px) {
        margin-top: 8px;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 8px;
        font-size: 14px;
      }
    }
    .price {
      margin-top: 40px;
      font-size: 16px;
      @media only screen and (max-width: 992px) {
        margin-top: 20px;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 10px;
        font-size: 14px;
      }
      span:first-child {
        font-size: 25px;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      span:nth-child(2) {
        opacity: 0.68;
      }
    }
    .try-button {
      margin-top: 16px;
      width: 100%;
      height: 48px;
      border-radius: 8px !important;
      background: var(--primary-color, #5e8d54);
      color: white;
      font-size: 20px;
      font-weight: 700;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  #trial-subscription-btn{
    font-weight: 600;
  }
  .right-section {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      @media only screen and (max-width: 767px) {
        height: unset
      }
      @media only screen and (max-width: 1024px) {
        height: 100%;
      }
    }
  }
  .submit-btn{
    width: 100% !important;
    height: 63px;
    @media only screen and (max-width: 767px) {
      height: 56px
    }
  }
  .outline-btn{
    width: 100% !important;
    height: 63px !important;
    @media only screen and (max-width: 767px) {
      height: 56px
    }
  }
  .outline-btn:not(#trial-subscription-btn){
    white-space: normal;
    word-wrap: break-word;
  }
`;

export default WithDirection(GetReadyWrapper);
