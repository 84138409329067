import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const FormPage = styled.div`
width: 100%;
height: 100%;
.ant-spin-dot-item{
    background-color: #729D68 !important;
  }
.ant-timeline-item-head.ant-timeline-item-head-green {
  color: red !important;
  border-color: red !important;
}
// .react-collapsible-theme .react-collapsible thead > tr > th {
//   background-color:white;
// }
// tr:nth-child(odd) { background-color: #f1f3f6; }
// tr:nth-child(even) {background-color: white;}


.table-striped-rows thead {
  background-color: #f1f1f1;
}
.dot-btn {
  span {
    rotate: 90deg;
    cursor: pointer;
  }
}
.startEndDate{
  border:1px solid #d9d9d9 !important;
  border-radius: 8px !important;
height: 38px;
padding: 5px !important;
margin-bottom: 1px !important;
// margin-left: 10px !important;
}
.ant-popover-inner {
  border-radius: 12px !important;
}
.btn-success {
  background-color: #65bb38;
  border-color: #559f2f;
}
.manage-property-title {
  color: #101828;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.manage-table .react-collapsible tbody .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
.manage-property-header {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  margin: auto;
}

.manage-property-header > .mp-title {
  min-width: 15%;
  max-width : 50%;
}
.manage-property-table {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background: white;
  .ant-pagination {
    padding: 24px;
  }
  .add-property-btn {
    background: #f80e46;
    border-radius: 8px !important;
    color: #fff;
  }
  .m-p-main {
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }
  .dropdown {
    border: 1px solid #d0d5dd !important;
  }
}
.action-btn {
  font-size: 12px;
  line-height: 24px;
  background: #535763;
  border-radius: 10px !important;
  border-color: transparent !important;
  color: white;
}
.button-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.fAgWDa .ant-table-content {
  overflow-x: unset;
}
.fAgWDa {
  overflow-x: unset;
}
.dtr-data {
  white-space: break-spaces;
}
.delete-button {
  margin-left: 5px;
}
.search-bar {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 12px;
  border: none;
  height:40px;
  margin-left: 2rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  flex-direction: row-reverse;
  max-width: 228px;
}
.site-form-item-icon {
  height: 19px;
  width: 19px;
  color: #8F92A1;
  font-size: 19px;
}
.site-form-item-cancel {
  height: 15px;
  width: 15px;
  color: #8F92A1;
  font-size: 15px;
}
.search-bar .ant-input {
  background: none!important;
  border: none!important;
  color: #8F92A1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
  .cstmData{ 
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #000000;
    margin-bottom : 10px;
  }
  .title{
    border-radius : 8px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .content{
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
  }
  .cardValue{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;

  }
  .button-group .ant-select-selector{
    height : 40px !important;
  }
  thead{
    text-align : left;
  }
  .btn-action{
    background: #ECDD5D;
    border :  #ECDD5D;
    color : #1E1E1E;
  }
  .lableAction{
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #1E1E1E;

  }
  .table_forms{
    border-top: 1px solid #EAECF0;
  }
  .selectProperty{
    min-width  : 150px;
    max-width: 100%;
  }
 @media only screen and (max-width : 768px){
  .manage-property-header {
   flex-wrap : wrap;
  }
  .button-group {
    margin : 10px 10px 0px 0px;

    justify-content: flex-start;
  }
 }
 @media only screen and (max-width : 425px){
  
  .button-group {
    flex-wrap: wrap;
    .btn-success{
      max-width : 48%;
      min-width : 30%;
    }
  }
  .startEndDate{
    max-width : 100%;
  }
 }
 @media only screen and (max-width : 375px){
  
 @media only screen and (max-width : 425px){
  
  .search-bar{
    margin-left : 0rem;
  }
  .selectProperty{
    min-width  : 100%;
  }
  .btn-success{
    max-width : 100% !important;
    min-width : 100% !important;
  }
 }
 
`;

export default WithDirection(FormPage);